import { createSelector } from 'reselect';
import { PropChallengeChallengeStatusEnum, PropChallengeChallengeTypeEnum } from 'services/generatedClientFromSwagger';
import { RootState } from 'store/index';

const prop_myPlansReducerSelector = (state: RootState) => state.prop_myPlans;

export const prop_getPlans = createSelector(prop_myPlansReducerSelector, (store) => {
    return store.plans;
});

export const prop_myPlans_getChallengesLoader = createSelector(
    prop_myPlansReducerSelector,
    (store) => store.challengesLoader
);

export const prop_myPlans_validateWithdrawalData = createSelector(
    prop_myPlansReducerSelector,
    (store) => store.validateWithdrawalData
);
export const prop_myPlans_isStartFundedAlertOpen = createSelector(
    prop_myPlansReducerSelector,
    (store) => store.isStartFundedAlertOpen
);

export const prop_myPlans_getChallengeIframeLoading = createSelector(
    prop_myPlansReducerSelector,
    (store) => store.challengeIframeLoading
);

export const prop_myPlans_setFundedChallengeLoader = createSelector(
    prop_myPlansReducerSelector,
    (store) => store.setFundedChallengeLoader
);

export const prop_myPlans_getChallengeIframe = createSelector([prop_myPlansReducerSelector], (store) => {
    if (store.selectedChallenge?.tpAccount) {
        return store.challengeIframe?.[store.selectedChallenge?.tpAccount];
    }
});

export const prop_myPlans_getSelectedChallenge = createSelector(prop_myPlansReducerSelector, (store) => {
    return store.selectedChallenge;
});

export const prop_myPlans_getMainAccountTp = createSelector(
    prop_myPlansReducerSelector,
    (store) => store.mainAccountTp
);

export const prop_myPlans_iframePopUp = createSelector(
    prop_myPlansReducerSelector,
    (store) => store.challengeIframePopup
);

export const prop_myPlans_getFundedChallenges = createSelector(prop_myPlansReducerSelector, (store) => {
    return store.fundedPropChallenge;
});

export const prop_myPlans_fundsFilterOptions = createSelector(
    prop_myPlansReducerSelector,
    (store) => store.fundsFilterOptions
);
export const prop_myPlans_isKycDialogOpen = createSelector(
    prop_myPlansReducerSelector,
    (store) => store.isKycDialogOpen
);
//need to change
export const prop_myPlans_freeTrialNumbers = createSelector(prop_myPlansReducerSelector, (store) => {
    const { limitAccountsNum, maxFreeTrialLimit, loading } = store.challenges.reduce(
        (acc: { limitAccountsNum: number; maxFreeTrialLimit: number; loading: boolean }, curr, index) => {
            if (curr.challengeType === PropChallengeChallengeTypeEnum.FreeTrial) {
                if (curr.challengeStatus === PropChallengeChallengeStatusEnum.Active) {
                    acc.limitAccountsNum += 1;
                } else {
                    acc.maxFreeTrialLimit += 1;
                }
            }
            const isLast = index === store.challenges.length - 1;
            acc.loading = isLast ? false : true;
            return acc;
        },
        { limitAccountsNum: 0, maxFreeTrialLimit: 0, loading: store?.challenges?.length > 0 }
    );
    return { limitAccountsNum, maxFreeTrialLimit, loading };
});

//remove
export const prop_myPlans_getSelectedChallengeAddon = createSelector(prop_myPlansReducerSelector, (store) => {
    const selectedChallenge = store.selectedChallenge;
    const plans = store.plans;
    if (!selectedChallenge || !plans) {
        return undefined;
    }
    return plans.find((plan) => plan.pPlan?.planId === selectedChallenge.plan)?.addOns;
});

export const prop_myPlans_manualKycLoading = createSelector(
    prop_myPlansReducerSelector,
    (store) => store.manualKycLoading
);

export const prop_myPlans_getSelectedChallengePlan = createSelector(prop_myPlansReducerSelector, (challenges) => {
    return challenges.selectedChallengePlan;
});
