import { useSelector } from 'react-redux';
import { RootState } from 'store/index';
import { PropChallenge, PropPlan } from 'services/generatedClientFromSwagger';
import { useEnableRetryLogic } from './useEnableRetryLogic';

export const useEnableRetryButton = () => {
    const {
        selectedChallenge,
        selectedChallengePlan,
    }: { selectedChallenge: PropChallenge; selectedChallengePlan: PropPlan } = useSelector((state: RootState) => {
        return {
            selectedChallenge: state.prop_myPlans.selectedChallenge,
            selectedChallengePlan: state.prop_myPlans.selectedChallengePlan,
        };
    });

    const { gapInMilliseconds, isRetryActive } = useEnableRetryLogic({
        challenge: selectedChallenge,
        plan: selectedChallengePlan,
    });

    return { gapInMilliseconds, isRetryActive, selectedChallengePlan, selectedChallenge };
};
