import { useSelector } from 'react-redux';
import { RootState } from 'store/index';

const useSelectedPlanAndSelectedChallenge = () => {
    const { selectedChallenge, selectedPlan } = useSelector((state: RootState) => {
        return {
            selectedChallenge: state.prop_myPlans.selectedChallenge,
            selectedPlan: state.prop_myPlans.selectedChallengePlan,
        };
    });

    return { selectedChallenge, selectedPlan };
};

export default useSelectedPlanAndSelectedChallenge;
