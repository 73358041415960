import { PropGoal } from 'services/generatedClientFromSwagger';
import { EPropNewChallengeTypes } from './actionsTypes';
import { PropNewChallengeReducerState } from './interfaces';

const initialState: PropNewChallengeReducerState = {
    getNewChallengeOptions: [],
    getNewChallengeOptionsLoader: false,
    getNewChallengeOptionsError: false,
    challengePaymentData: {},
    newGoalsLoader: false,
    newGoalsError: false,
    newGoals: [],
};

const PropNewChallengeReducer = (
    state = initialState,
    { type, payload }: { type: EPropNewChallengeTypes; payload: any }
) => {
    switch (type) {
        case EPropNewChallengeTypes.GET_NEW_CHALLENGE_OPTIONS_START:
            return {
                ...state,
                getNewChallengeOptionsLoader: true,
            };
        case EPropNewChallengeTypes.GET_NEW_CHALLENGE_OPTIONS_SUCCESS:
            return {
                ...state,
                getNewChallengeOptionsLoader: false,
                getNewChallengeOptions: payload,
            };
        case EPropNewChallengeTypes.GET_NEW_CHALLENGE_OPTIONS_FAIL:
            return {
                ...state,
                getNewChallengeOptionsLoader: false,
                getNewChallengeOptionsError: true,
            };
        case EPropNewChallengeTypes.GET_NEW_GOAL_OPTIONS_START:
            return {
                ...state,
                getNewChallengeOptionsLoader: true,
            };
        case EPropNewChallengeTypes.GET_NEW_GOAL_OPTIONS_SUCCESS:
            return {
                ...state,
                getNewChallengeOptionsLoader: false,
                newGoals: payload.sort((a: PropGoal, b: PropGoal) => (a?.positionId ?? 0) - (b?.positionId ?? 0)),
            };
        case EPropNewChallengeTypes.GET_NEW_GOAL_OPTIONS_FAIL:
            return {
                ...state,
                getNewChallengeOptionsLoader: false,
                newGoalsError: true,
            };
        case EPropNewChallengeTypes.SET_CHALLENGE_DATA:
            return {
                ...state,
                challengePaymentData: payload,
            };

        default:
            return state;
    }
};

export default PropNewChallengeReducer;
