import { useEffect, useState } from 'react';

export type UseGetTimeGapChallengeRetryProps = {
    gapInMilliseconds: number;
};
export type UseGetTimeGapChallengeRetryState = {
    time: { days: number; hours: number; minutes: number; seconds: number };
};
const handleCountdown = (gapInMilliseconds: number) => {
    return () => {
        const now = Date.now();
        const gap = new Date(gapInMilliseconds).getTime() - now;
        const days = Math.floor(gap / (1000 * 60 * 60 * 24));
        const hours = Math.floor((gap % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((gap % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((gap % (1000 * 60)) / 1000);

        return { days, hours, minutes, seconds };
    };
};
const useGetTimeGapChallengeRetry = ({
    gapInMilliseconds,
}: UseGetTimeGapChallengeRetryProps): UseGetTimeGapChallengeRetryState => {
    const [time, setTime] = useState<any>(handleCountdown(gapInMilliseconds));

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setTime(handleCountdown(gapInMilliseconds));
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [gapInMilliseconds, time]);
    return { time };
};

export default useGetTimeGapChallengeRetry;
