import React from 'react';
import Typography from 'components/Typography';
import Checkbox from 'components/Inputs/Checkbox';
import { useTranslation } from 'react-i18next';
import useStyles from '../fundedInvestingManualKycDialog.styles';
import Button from 'components/Button';
import config from 'config/common';

import i18n from 'i18n';
import { useTheme } from '@material-ui/core';

interface TermsContentProps {
    isCheckboxChecked: boolean;
    handleCheckboxChange: () => void;
    onSubmit: () => void;
}

const TermsStep: React.FunctionComponent<TermsContentProps> = ({
    isCheckboxChecked,
    handleCheckboxChange,
    onSubmit,
}) => {
    const classes = useStyles();
    const { t } = useTranslation('prop');

    const { language } = i18n;
    const theme = useTheme();

    return (
        <>
            <Typography className={classes.header} variant="tLgSemibold">
                {t('investingManualKyc.terms.header')}
            </Typography>
            <Typography className={classes.subTxtFundedInvestingManualKycDialog} variant="tSmallRegular">
                {t('investingManualKyc.terms.subTxt')}
            </Typography>
            <Checkbox
                checked={isCheckboxChecked}
                onChange={handleCheckboxChange}
                disableSidesMargin
                labelPlacement={'end'}
                label={t('investingManualKyc.terms.checkbox')}
                className={classes.checkbox}
                customLabel={
                    <>
                        <Typography variant="tSmallRegular">
                            {t('investingManualKyc.terms2.checkbox')}
                            {config.refund_cancellation_policy_modal ? (
                                <a
                                    style={{ color: theme.palette.primary.main }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={config.refund_cancellation_policy_modal}
                                >
                                    {t('investingManualKyc.terms2.link')}
                                </a>
                            ) : (
                                t('investingManualKyc.terms2.link')
                            )}
                        </Typography>
                    </>
                }
            />
            <Button
                disabled={!isCheckboxChecked}
                className={classes.btn}
                variant="contained"
                color="primary"
                onClick={onSubmit}
            >
                {t(`investingManualKyc.terms.btn`)}
            </Button>
        </>
    );
};

export default TermsStep;
