import { prop_myPlans_getSelectedChallenge } from 'features/prop/store/plans/selectors';
import { PropChallenge } from 'services/generatedClientFromSwagger';

export interface ChallengeRetryDisabledDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onAccept: () => void;
    selectedChallenge: PropChallenge;
}

export const mapStateToProps = (state) => ({
    selectedChallenge: prop_myPlans_getSelectedChallenge(state),
});

export const mapDispatchToProps = {};
