import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import thunk from 'redux-thunk';

import systemReducer from './system/reducer';
import { SystemReducerState } from './system/interfaces';

import authReducer from './auth/reducer';
import { AuthReducerState } from './auth/interfaces';

import userReducer from './user/reducer';
import { UserReducerState } from './user/interfaces';

import AccountsReducer from './accounts/reducer';
import { AccountsReducerState } from './accounts/interfaces';

import PlatformReducer from './platform/reducer';
import { PlatformReducerState } from './platform/interfaces';

import AnalyticsReducer from './analytics/reducer';
import { AnalyticsReducerState } from './analytics/interfaces';

import PaymentReducer from './payment/reducer';
import { PaymentReducerState } from './payment/interfaces';

import KycReducer from 'features/kyc/store/reducer';
import { KycReducerState } from 'features/kyc/store/interface';

import SumsubReducer from 'features/sumsub/store/reducer';
import { SumsubReducerState } from 'features/sumsub/store/interface';

import UploadDReducer from 'features/upload-documents/store/reducer';
import { UploadDReducerState } from 'features/upload-documents/store/interface';

import NotificationsReducer from 'features/Notifications/store/reducer';
import { NotificationReducerState } from 'features/Notifications/store/interface';

import ReverseSolicitationReducer from 'features/ReverseSolicitation/store/reducer';
import { ReverseSolicatationState } from 'features/ReverseSolicitation/store/interface';

import config from 'config/common';
import { SymbolsReducerState } from './interfaces';

import { ConfigReducerState } from './config/interfaces';
import configReducer from './config/reducer';

import { PropMyPlansReducerState } from 'features/prop/store/plans/interfaces';
import PropMyPlanssReducer from 'features/prop/store/plans/reducer';

import { PropNewChallengeReducerState } from 'features/prop/store/new-challenge/interfaces';
import PropNewChallengeReducer from 'features/prop/store/new-challenge/reducer';

import { PropPayoutReducerState } from 'features/prop/store/payout/interfaces';
import PropPayoutReducer from 'features/prop/store/payout/reducer';

import { PropCheckoutReducerState } from 'features/prop/store/checkout/interfaces';
import PropCheckoutReducer from 'features/prop/store/checkout/reducer';

export interface RootState {
    symbols: SymbolsReducerState;
    system: SystemReducerState;
    auth: AuthReducerState;
    user: UserReducerState;
    accounts: AccountsReducerState;
    platform: PlatformReducerState;
    analytics: AnalyticsReducerState;
    payment: PaymentReducerState;
    kyc: KycReducerState;
    sumsub: SumsubReducerState;
    uploadD: UploadDReducerState;
    notification: NotificationReducerState;
    reverseSolicitation: ReverseSolicatationState;
    config: ConfigReducerState;
    prop_myPlans: PropMyPlansReducerState;
    prop_newChallenge: PropNewChallengeReducerState;
    prop_payout: PropPayoutReducerState;
    prop_checkout: PropCheckoutReducerState;
}

const rootReducer = combineReducers({
    system: systemReducer,
    auth: authReducer,
    user: userReducer,
    accounts: AccountsReducer,
    platform: PlatformReducer,
    analytics: AnalyticsReducer,
    payment: PaymentReducer,
    kyc: KycReducer,
    sumsub: SumsubReducer,
    uploadD: UploadDReducer,
    notification: NotificationsReducer,
    reverseSolicitation: ReverseSolicitationReducer,
    config: configReducer,
    prop_myPlans: PropMyPlanssReducer,
    prop_newChallenge: PropNewChallengeReducer,
    prop_payout: PropPayoutReducer,
    prop_checkout: PropCheckoutReducer,
});

const middleWares = applyMiddleware(thunk);
const middleWaresWithDevtools = composeWithDevTools(applyMiddleware(thunk));

export default createStore(rootReducer, config.enableReduxDevtools ? middleWaresWithDevtools : middleWares);
