import { IconButton, createStyles, makeStyles } from '@material-ui/core';
import { AlertList } from 'components/Alert/CustomAlertList/AlertList.render';
import { UploadedFile } from 'components/Inputs/DropZone/dropzone.render';
import React, { FunctionComponent, memo, useCallback, useMemo } from 'react';
import Typography from 'components/Typography';
import IconBox from 'components/IconBox/iconBox.render';
import Icon, { IconsType } from 'components/Icon';
import { Trash } from '../uploadIcons/UploadIcons';
import prettyBytes from 'pretty-bytes';
import { connect } from 'react-redux';
import { uploadD_filesUploadingState } from 'features/upload-documents/store/selector';
import { RootState } from 'store/index';
import { FileUpload, FileUploadStatus } from 'features/upload-documents/store/interface';
import { isRtl } from 'helpers/device_helper';

export type FileDisplayProps = {
    files: UploadedFile[];
    handleRemoveFile: (file: UploadedFile) => void;
    uploadD_processLoading: boolean;
    uploadD_filesUploadingState: {
        [file: string]: FileUpload;
    };
};

const iconMapping = {
    error: <IconBox size={32} variant="border" color={'error'} iconType={IconsType.fileIcon}></IconBox>,
    success: <Icon name={IconsType.checkCircle} />,
    info: <IconBox size={32} variant="border" color={'primary'} iconType={IconsType.fileIcon}></IconBox>,
    warning: <Icon name={IconsType.alert_triangle} />,
};

const useStyles = makeStyles((theme) =>
    createStyles({
        alert_item: {
            marginBottom: 16,
            borderRadius: 8,
            padding: 16,

            '& .MuiAlert-action': {
                alignItems: 'flex-start',
                marginRight: 0,
                paddingLeft: 0,
                ...isRtl({ marginLeft: 0, marginRight: 'auto' }),
            },
            '& .MuiAlert-icon': {
                alignItems: 'flex-start',
                padding: 0,
                ...isRtl({ marginLeft: 12, marginRight: 0 }),
            },
            '& .MuiAlert-message': {
                padding: 0,
            },
        },
        alert_item_last: {
            marginBottom: 0,
        },
        alert_container: { display: 'flex', flexDirection: 'column', height: 'fit-content' },
        ellipsis: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: 150,
        },
    })
);

const renderAlertContent = (classes: any) => (file: UploadedFile) => (
    <>
        <Typography className={classes.ellipsis} component={'div'} variant="tSmallMedium">
            {file.name}
        </Typography>
        {file.errorMessage && <Typography variant="tSmallRegular">{file.errorMessage}</Typography>}
        <Typography component={'div'} variant="tSmallRegular">
            {file.size && prettyBytes(file.size)}
        </Typography>
    </>
);

const mapStateToProps = (state: RootState) => ({
    uploadD_filesUploadingState: uploadD_filesUploadingState(state),
});

const mapDispatchToProps = {};

const FileDisplay: FunctionComponent<FileDisplayProps> = ({ files, handleRemoveFile, uploadD_filesUploadingState }) => {
    const actionIconMapping = useMemo(
        () => ({
            error: <Trash variant="error" />,
            info: <Trash variant="info" />,
        }),
        []
    );

    const renderAlertAction = useCallback(
        (handleClose: () => void, severity: string) => (
            <IconButton onClick={handleClose} color="inherit" size="small">
                {actionIconMapping[severity]}
            </IconButton>
        ),
        [actionIconMapping]
    );

    const classes = useStyles();
    const fileAlertsItems = useMemo(() => {
        return files.map((file, index) => {
            const isLast = index === files.length - 1;
            const fileUploadingState = uploadD_filesUploadingState?.[file?.name || ''];
            const severityLevel =
                (!fileUploadingState?.status || fileUploadingState?.status !== FileUploadStatus.FAILED) &&
                !file?.errorMessage
                    ? 'info'
                    : 'error';

            return {
                loading: fileUploadingState?.loading,
                renderAction: renderAlertAction,
                iconMapping: iconMapping,
                renderItem: renderAlertContent(classes),
                elementKey: `${file.name}_${index}`,
                item: file,
                variant: 'outlined',
                severity: severityLevel,
                className: `${classes.alert_item} ${isLast && classes.alert_item_last}`,
            };
        });
    }, [classes, files, renderAlertAction, uploadD_filesUploadingState]);

    return (
        <div className={classes.alert_container}>
            <AlertList handleRemoveItem={handleRemoveFile} items={fileAlertsItems} />
        </div>
    );
};

const FileDisplayWithStoreConnection = connect(mapStateToProps, mapDispatchToProps)(memo(FileDisplay));
export default FileDisplayWithStoreConnection;
