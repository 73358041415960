import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import Root from 'Root';
import * as serviceWorker from './serviceWorker';
import './index.css';
import SplashLoaderFullScreen from 'components/SpalshLoaderFullScreen';

ReactDOM.render(
    <Suspense fallback={<SplashLoaderFullScreen withoutLoader />}>
        <Root />
    </Suspense>,
    document.getElementById('root')
);

serviceWorker.unregister();
