import React, { useCallback } from 'react';
import FundedInvestingManualKycDialog from './fundedInvestingManualKycDialog.render';
import { KycDialogOpen } from 'features/prop/interfaces';
import {
    prop__myPlans_acceptChallengeTermsAndCondition,
    prop__myPlans_updateStatusManualKyc,
    prop_handleChallengeStartFundedAlert,
    prop_handleKycDialogState,
} from 'features/prop/store/plans/actions';
import { connect } from 'react-redux';
import { prop_myPlans_isKycDialogOpen, prop_myPlans_manualKycLoading } from 'features/prop/store/plans/selectors';
import { user_userInfo } from 'store/user/selectors';
import { AccountInfoParsed, ManualKycRequest } from 'services/generatedClientFromSwagger';

export interface FundedInvestingManualKycDialogPropsWithStoreConnectionProps {
    isDialogOpen: KycDialogOpen;
    handleKycDialogState: (isDialogOpen: KycDialogOpen) => void;
    acceptChallengeTermsAndCondition: (challengeId: string) => void;
    userInfo: AccountInfoParsed;
    updateStatusManualKyc: (manualKycRequest: ManualKycRequest) => void;
    manualKycLoading: boolean;
    handleChallengeStartFundedAlert: (challengeId: string, isOpen: boolean, sent: boolean) => Promise<void>;
}

export const mapDispatchToProps = {
    handleKycDialogState: prop_handleKycDialogState,
    acceptChallengeTermsAndCondition: prop__myPlans_acceptChallengeTermsAndCondition,
    updateStatusManualKyc: prop__myPlans_updateStatusManualKyc,
    handleChallengeStartFundedAlert: prop_handleChallengeStartFundedAlert,
};

export const mapStateToProps = (state) => ({
    isDialogOpen: prop_myPlans_isKycDialogOpen(state),
    userInfo: user_userInfo(state),
    manualKycLoading: prop_myPlans_manualKycLoading(state),
});

const FundedInvestingManualKycDialogWithStoreConnection: React.FunctionComponent<
    FundedInvestingManualKycDialogPropsWithStoreConnectionProps
> = ({
    handleKycDialogState,
    isDialogOpen,
    acceptChallengeTermsAndCondition,
    userInfo,
    updateStatusManualKyc,
    manualKycLoading,
    handleChallengeStartFundedAlert,
}) => {
    const onAccept = useCallback(() => {
        if (isDialogOpen) {
            acceptChallengeTermsAndCondition(isDialogOpen.id);
        }
        handleChallengeStartFundedAlert(isDialogOpen?.id ?? '', true, true);
        handleKycDialogState(undefined);
    }, [acceptChallengeTermsAndCondition, handleChallengeStartFundedAlert, handleKycDialogState, isDialogOpen]);

    const onClose = useCallback(() => {
        handleChallengeStartFundedAlert(isDialogOpen?.id ?? '', true, false);
        handleKycDialogState(undefined);
    }, [handleChallengeStartFundedAlert, handleKycDialogState, isDialogOpen?.id]);

    return (
        <FundedInvestingManualKycDialog
            key={`FundedInvestingManualKycDialog_${isDialogOpen?.id}`}
            manualKycLoading={manualKycLoading}
            userInfo={userInfo}
            isOpen={!!isDialogOpen}
            onClose={onClose}
            onAccept={onAccept}
            updateStatusManualKyc={updateStatusManualKyc}
            acceptChallengeTermsAndCondition={acceptChallengeTermsAndCondition}
            challengeId={isDialogOpen?.id ?? ''}
        />
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(FundedInvestingManualKycDialogWithStoreConnection);
