import { useMemo } from 'react';

export const useRetryPlanDuration = ({ endTime, retryExpirationDays }) => {
    return useMemo(() => {
        const endTimeAsNumber = Date.parse(endTime ?? '');
        const endTimeDate = new Date(endTimeAsNumber);

        endTimeDate.setDate(endTimeDate.getDate() + (retryExpirationDays ?? 0));
        const gapInMilliseconds = endTimeDate.getTime();
        const isExpirationDateRetryActive = endTimeDate > new Date();

        return { isExpirationDateRetryActive, gapInDays: gapInMilliseconds / 1000 / 60 / 60 / 24, gapInMilliseconds };
    }, [endTime, retryExpirationDays]);
};
