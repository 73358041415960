import React, { useCallback } from 'react';
import { IconsType } from 'components/Icon';
import Dialog from 'components/Dialog';
import useStyles from './challengeRetryDisabledDialog.styles';
import { Trans, useTranslation } from 'react-i18next';
import Button from 'components/Button';
import Typography from 'components/Typography';
import IconBox from 'components/IconBox/iconBox.render';
import { Pages } from 'interfaces/main';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    ChallengeRetryDisabledDialogProps,
    mapDispatchToProps,
    mapStateToProps,
} from './challengeRetryDisabledDialog.scheme';
import { useNonRetryOption } from 'features/prop/hooks/useNonRetryOption';
const challengeMemoryKey = 'ChallengeRetryDisabledDialog';
const ChallengeRetryDisabledDialog: React.FunctionComponent<ChallengeRetryDisabledDialogProps> = (props) => {
    const { selectedChallenge: challenge } = props;
    const counter = +(localStorage.getItem(`${challengeMemoryKey}_${challenge?.id}`) ?? 0);
    const [isOpen, setIsOpen] = React.useState(true);
    const displayNonRetryOptionsModal = useNonRetryOption();
    const classes = useStyles();
    const { t } = useTranslation('prop');
    const history = useHistory();

    const handleBackButton = useCallback(() => {
        history.push(`/${Pages.PROP}/${Pages.PROP__MY_CHALLENGES}`);
        setIsOpen(false);
    }, [history]);

    const handleRetryButton = useCallback(() => {
        history.push(`/${Pages.PROP}/${Pages.PROP__NEW_CHALLENGES}`);
        setIsOpen(false);
    }, [history]);

    const handleClose = useCallback(() => {
        setIsOpen(false);
        localStorage.setItem(
            `${challengeMemoryKey}_${challenge?.id}`,
            `${+(localStorage.getItem(`${challengeMemoryKey}_${challenge?.id}`) ?? 0) + 1}`
        );
    }, [challenge?.id]);

    return (
        <Dialog
            open={displayNonRetryOptionsModal && isOpen && counter < 1}
            onClose={handleClose}
            classes={{ paper: classes.dialogPaper, root: classes.backDrop }}
            closeButtonIcon={{ icon: IconsType.x_close, className: classes.closeIcon }}
        >
            <div className={classes.iconsContainer}>
                <IconBox iconType={IconsType.retry_disabled} color={'success'} variant={'border'} size={48} />
            </div>
            <Typography className={classes.title} variant="tLgSemibold" component="h2">
                {t('challengeRetryDisabledDialog.title')}
            </Typography>
            <Typography className={classes.id} variant="tSmallSemibold">
                {t('challengeRetryDisabledDialog.id')}
                {challenge?.tpName}
            </Typography>
            <Typography variant="tSmallRegular" className={classes.description}>
                <Trans
                    i18nKey="prop:challengeRetryDisabledDialog.description"
                    components={{
                        start: <span className={classes.start}></span>,
                    }}
                />
            </Typography>
            <div className={classes.buttonGroup}>
                <Button className={classes.backButton} variant="outlined" color="inherit" onClick={handleBackButton}>
                    <Typography className={classes.backBtnText} variant="tMdSemibold">
                        {t('challengeRetryDisabledDialog.backButton')}
                    </Typography>
                </Button>

                <Button className={classes.button} variant="contained" color="primary" onClick={handleRetryButton}>
                    {t('challengeRetryDisabledDialog.startNew')}
                </Button>
            </div>
        </Dialog>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ChallengeRetryDisabledDialog));
