import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    retryExpiration: {
        color: theme.palette.grayVariants[600],
        marginBottom: '8px !important',
        textAlign: 'center',
        fontWeight: 700,
    },

    timeBoxes: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '32px',
    },

    timeBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '48px',
        height: '48px',
        padding: '8px',
        borderRadius: '4px',
        border:
            theme.palette.type === 'dark'
                ? `1px solid ${theme.palette.grayVariants[500]}`
                : `1px solid ${theme.palette.grayVariants[300]}`,
        margin: '4px',
    },
    label: {
        color: theme.palette.type === 'dark' ? theme.palette.grayVariants[700] : theme.palette.grayVariants[600],
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '18px',
    },
    colon: {
        color: theme.palette.type === 'dark' ? theme.palette.grayVariants[700] : theme.palette.grayVariants[600],
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '30px',
    },
}));
