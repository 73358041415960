import { makeStyles } from '@material-ui/core';
import { customBreakPoint } from 'helpers/device_helper';
import { newShadows } from 'muiTheme/config/palette';

export default makeStyles((theme) => ({
    btn_wrapper: {},
    propRetryBtn: {
        padding: '10px 16px',
        boxShadow: newShadows.xs,
        borderRadius: '8px',
        border: '1px solid #2ED3B7',
        height: '44px',
        maxWidth: '120px',
        '&>.MuiButton-label': {
            gap: '4px',
            lineHeight: 2.4,
        },
        ...customBreakPoint(1200, {
            maxWidth: 'unset',
        }),
    },
    retry_color: {
        color: theme.palette.grayVariants[900],
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    spinArrow: {
        width: '24px',
        height: '24px',
    },
}));
