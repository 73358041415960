import {
    prop_myPlans_getMainAccountTp,
    prop_myPlans_getSelectedChallenge,
    prop_myPlans_getSelectedChallengePlan,
} from 'features/prop/store/plans/selectors';
import { PropChallenge, PropPlan, TradingPlatformAccountInfo } from 'services/generatedClientFromSwagger';

export interface ChallengeRetryDialogProps {
    selectedChallenge: PropChallenge;
    selectedChallengePlan: PropPlan;
    selectedTpAccount: TradingPlatformAccountInfo | undefined;
}

export const mapStateToProps = (state) => ({
    selectedChallenge: prop_myPlans_getSelectedChallenge(state),
    selectedChallengePlan: prop_myPlans_getSelectedChallengePlan(state),
    selectedTpAccount: prop_myPlans_getMainAccountTp(state),
});

export const mapDispatchToProps = {};
