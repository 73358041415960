import { useSelector } from 'react-redux';
import { PropPlan } from 'services/generatedClientFromSwagger';
import { RootState } from 'store/index';

export const useRetryDetails = (retryPlanId: string) => {
    const retryPlan: PropPlan = useSelector((state: RootState) =>
        state.prop_myPlans.plans.find((plan) => plan.pPlan?.planId === retryPlanId)
    );

    return getRetryPlanData(retryPlan);
};
export function getRetryPlanData(retryPlan: PropPlan) {
    const planPriceAfterDiscount =
        (retryPlan?.pPlan?.totalOrderSummaryAmount ?? 0) - (retryPlan?.pPlan?.retryOptions?.retryDiscountAmount ?? 0);

    const addOns = retryPlan?.addOns?.map((addOn) => addOn.type) || [];

    const sanitizedAddOns = addOns.filter((addOn) => addOn !== undefined);

    return {
        planType: retryPlan?.pPlan?.name || '',
        planPriceBeforeDiscount: retryPlan?.pPlan?.totalOrderSummaryAmount || 0,
        planPriceAfterDiscount: planPriceAfterDiscount,
        conditionsValues: retryPlan?.originalAdditionalInfo?.challenges?.propsEngine,
        discountAmount: retryPlan?.pPlan?.retryOptions?.retryDiscountAmount || 0,
        challengeAmount: retryPlan?.pPlan?.basicDepositAmount || 0,
        addOns: sanitizedAddOns,
    };
}
