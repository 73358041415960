import React from 'react';
import useStyles from './timeBox.styles';
import { useTranslation } from 'react-i18next';
import Typography from 'components/Typography';
import useGetTimeGapChallengeRetry from 'features/prop/hooks/useGetTimeGapChallengeRetry';

interface ChallengeRetryTimeProps {
    gapInMilliseconds: number;
}

const ChallengeRetryTime: React.FunctionComponent<ChallengeRetryTimeProps> = ({ gapInMilliseconds }) => {
    const classes = useStyles();
    const { t } = useTranslation('prop');

    return (
        <div>
            <Typography className={classes.retryExpiration} variant="tSmallRegular">
                {t('challengeRetryDialog.retryExpiration')}
            </Typography>
            <TimeBoxes gapInMilliseconds={gapInMilliseconds} />
        </div>
    );
};

interface TimeBoxesProps {
    gapInMilliseconds: number;
}

const TimeBox = ({ label, value }: { label: string; value: number }) => {
    const classes = useStyles();
    return (
        <div className={classes.timeBox}>
            <Typography variant="tMdSemibold">{value}</Typography>
            <Typography className={classes.label}>{label}</Typography>
        </div>
    );
};

const TimeBoxes: React.FC<TimeBoxesProps> = ({ gapInMilliseconds }) => {
    const classes = useStyles();
    const { time } = useGetTimeGapChallengeRetry({ gapInMilliseconds });

    return (
        <div className={classes.timeBoxes}>
            <TimeBox label="Days" value={time.days} />
            <div className={classes.colon}>:</div>
            <TimeBox label="Hours" value={time.hours} />
            <div className={classes.colon}>:</div>
            <TimeBox label="Minutes" value={time.minutes} />
            <div className={classes.colon}>:</div>
            <TimeBox label="Seconds" value={time.seconds} />
        </div>
    );
};

export default ChallengeRetryTime;
