import { RootState } from 'store/index';
import { useSelector } from 'react-redux';

export const useValidChallenges = () => {
    const validChallenges = useSelector((state: RootState) => state.prop_myPlans.filteredChallenges);
    return validChallenges;
};
export const useFundedChallenges = () => {
    const validChallenges = useSelector((state: RootState) => state.prop_myPlans.onlyFundedChallenges);
    return validChallenges;
};
