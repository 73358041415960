import React from 'react';
import { PageLayoutProps } from './page-layout.scheme';
import useStyles from './page-layout.styles';
import LoaderFullScreen from 'components/LoaderFullScreen';
import Breadcrumb from 'components/Breakcrumb';
import Typography from 'components/Typography';
import { Hidden, useTheme } from '@material-ui/core';
import PanelInfo from 'components/PanelInfo';
import { logoDarkMode, logoLightMode } from 'config/dynamicAssets';

const PageLayout: React.FunctionComponent<PageLayoutProps> = (props) => {
    const {
        sideImgSrc,
        sideTitle,
        sideText,
        showLogo,
        fullWidth,
        fullHeight,
        fullPageWidth,
        slimWidth,
        loading,
        disableSide,
        disablePadding,
        infoData,
        spacing,
        fixIosMobilePage,
        enabledBreadcrumbs,
        disableTopPadding,
        disableBottomPadding,
        innerContainerMaxWidth,
    } = props;
    const theme = useTheme();

    const classes = useStyles({
        fullWidth,
        fullHeight,
        fullPageWidth,
        disableSide,
        slimWidth,
        infoData,
        spacing,
        disablePadding,
        fixIosMobilePage,
        disableTopPadding,
        innerContainerMaxWidth,
        disableBottomPadding,
    });

    return (
        <div className={classes.container}>
            <div className={classes.innerContainer}>
                <div className={classes.contentContainer}>
                    {enabledBreadcrumbs && (
                        <span>
                            <Breadcrumb />
                        </span>
                    )}
                    <div className={classes.contentSide}>
                        <div className={classes.innerContentContainer}>{props.children}</div>
                    </div>
                </div>

                <Hidden smDown>
                    <div className={classes.imgSide}>
                        <div className={classes.imgSideContent}>
                            {infoData ? (
                                <PanelInfo />
                            ) : (
                                <React.Fragment>
                                    {sideImgSrc && (
                                        <div className={classes.sideImageWrapper}>
                                            <img src={sideImgSrc} alt="backgroundimage" className={classes.sideImage} />
                                        </div>
                                    )}
                                    {sideTitle && <Typography variant="h3">{sideTitle}</Typography>}
                                    {sideText && <Typography variant="body2">{sideText}</Typography>}
                                    {showLogo && (
                                        <img
                                            src={theme.palette.type === 'dark' ? logoDarkMode : logoLightMode}
                                            alt="backgroundimage"
                                            className={classes.logoImage}
                                        />
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </Hidden>
            </div>
            {loading && <LoaderFullScreen />}
        </div>
    );
};

export default PageLayout;
//
