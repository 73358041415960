import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { prop_myPlans_getChallengesLoader } from '../store/plans/selectors';
import { Pages } from 'interfaces/main';
import LoaderFullScreen from 'components/LoaderFullScreen';
import { useValidChallenges } from '../hooks/useValidChallenges';
import { useAllChallenges } from '../hooks/useAllChallenges';

interface PropGuardProps {
    component: any;
    hasFilteredChallengesGuard?: boolean;
    hasChallengesGuard?: boolean;
    loading: boolean;
    prop_myPlans_getChallengesLoader: boolean;
}

const PropGuard: React.FunctionComponent<PropGuardProps> = (props) => {
    const {
        component: Component,
        hasChallengesGuard,
        hasFilteredChallengesGuard,
        loading,
        prop_myPlans_getChallengesLoader,
        ...restProps
    } = props;

    const challenges = useAllChallenges();
    const validChallenges = useValidChallenges();
    if (hasChallengesGuard && (prop_myPlans_getChallengesLoader === undefined || prop_myPlans_getChallengesLoader)) {
        return <LoaderFullScreen />;
    }

    if (hasChallengesGuard && challenges.length < 1) {
        return <Redirect to={`/${Pages.PROP}/${Pages.PROP__NEW_CHALLENGES}`} />;
    }

    if (hasFilteredChallengesGuard && validChallenges.length === 0) {
        return <Redirect to={`/${Pages.PROP}/${Pages.PROP__NEW_CHALLENGES}`} />;
    }

    return <Route {...restProps} render={(props) => <Component {...props} />} />;
};

export const mapStateToProps = (state) => ({
    prop_myPlans_getChallengesLoader: prop_myPlans_getChallengesLoader(state),
});

export const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PropGuard));
