/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { UploadFileWithDocumentTypeRequest } from './upload-file-with-document-type-request';

/**
 *
 * @export
 * @interface ManualKycRequest
 */
export class ManualKycRequest {
    /**
     *
     * @type {string}
     * @memberof ManualKycRequest
     */
    challengeId?: string;
    /**
     *
     * @type {string}
     * @memberof ManualKycRequest
     */
    kycStatus: ManualKycRequestKycStatusEnum;
    /**
     *
     * @type {Array<UploadFileWithDocumentTypeRequest>}
     * @memberof ManualKycRequest
     */
    files?: Array<UploadFileWithDocumentTypeRequest>;
}

/**
 * @export
 * @enum {string}
 */
export enum ManualKycRequestKycStatusEnum {
    NotStarted = 'NotStarted',
    DocumentUploaded = 'DocumentUploaded',
    InProgress = 'InProgress',
    Resubmission = 'Resubmission',
    Rejected = 'Rejected',
    Approved = 'Approved',
    TraderAgreementAccepted = 'TraderAgreementAccepted',
}
