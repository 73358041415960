import { makeStyles } from '@material-ui/core';
import { customGap, isLaptop, isRtl, isTablet, customBreakPoint } from 'helpers/device_helper';

interface Props {
    fullWidth?: boolean;
    fullHeight?: boolean;
    disableSide?: boolean;
    fullPageWidth?: boolean;
    slimWidth?: boolean;
    infoData?: boolean;
    spacing?: number;
    disablePadding?: boolean;
    fixIosMobilePage?: boolean;
    disableTopPadding?: boolean;
    disableBottomPadding?: boolean;
    innerContainerMaxWidth?: string | number;
}

export default makeStyles((theme) => ({
    container: (props: Props) => ({
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    }),
    innerContainer: (props: Props) => ({
        height: '100%',
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr',
        maxWidth: props.innerContainerMaxWidth,
        ...isLaptop({
            gridTemplateColumns: props.disableSide ? '1fr' : '2fr 1fr',
        }),
        ...customBreakPoint(1650, {
            gridTemplateColumns: props.disableSide ? '1fr' : '3fr 1fr',
        }),
    }),
    contentContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    contentSide: (props: Props) => ({
        height: '100%',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }),
    innerContentContainer: (props: Props) => ({
        width: props.fullWidth ? '100vw' : 'auto',
        height: props.fullHeight ? '100%' : 'auto',
        ...(props.fixIosMobilePage && {
            minHeight: '72vh',
        }),
        display: 'flex',
        flexDirection: 'column',
        // maxWidth: props.fullPageWidth
        //     ? '100%'
        //     : props.slimWidth
        //     ? `calc(45.6rem + ${theme.spacing(14)}rem)`
        //     : `calc(73.0rem + ${theme.spacing(14)}rem)`,
        maxWidth: props.fullPageWidth || props.disableSide ? '100%' : `calc(110.0rem + ${theme.spacing(14)}rem)`,
        padding: props.disablePadding ? `0` : `${theme.spacing(7)}rem ${theme.spacing(4)}rem`,
        paddingTop: props.disableTopPadding && '0',
        paddingBottom: props.disableBottomPadding && '0',

        ...isTablet({
            width: props.fullWidth ? '100%' : 'auto',
            ...(props.fixIosMobilePage && {
                minHeight: 'unset',
            }),
            padding: props.disablePadding ? `0` : `${theme.spacing(4.75)}rem ${theme.spacing(7)}rem`,
            paddingTop: props.disableTopPadding && '0',
        }),
        ...customGap(theme.spacing(props.spacing || 5), 'vertical'),
    }),
    imgSide: (props: Props) => ({
        position: 'relative',
        display: props.disableSide ? 'none' : 'flex',
        alignItems: props.infoData ? 'initial' : 'center',
        justifyContent: 'center',
        padding: '2.8rem',
        '&:before': {
            content: "''",
            position: 'absolute',
            height: 'calc(100% + 7.3rem)',
            width: '0.1rem',
            background: theme.palette.action.disabledBackground,
            top: '-7.3rem',
            left: 0,
            right: 'unset',
            ...isRtl({
                right: 0,
                left: 'unset',
            }),
        },
    }),
    imgSideContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        maxWidth: '32rem',
        ...customGap(theme.spacing(3), 'vertical'),
    },
    sideImageWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    sideImage: {
        width: '100%',
        maxWidth: '42rem',
        maxHeight: '42rem',
    },
    logoImage: {
        maxHeight: '4rem',
    },
}));
