import React from 'react';
import useStyles from './bottom_navigation.mobile.styles';
import { connect } from 'react-redux';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Icon from 'components/Icon';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import {
    MobileBottomNavigationProps,
    navigationTabsScheme,
    mapStateToProps,
    BottomNavigationIds,
} from './bottom_navigation.mobile.scheme';
import { Pages } from 'interfaces/main';
import { useValidChallenges } from 'features/prop/hooks/useValidChallenges';

const MobileBottomNavigation: React.FunctionComponent<MobileBottomNavigationProps> = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState<BottomNavigationIds | undefined>(BottomNavigationIds.HOME);
    const history = useHistory();
    const { pathname } = useLocation();
    const validChallenges = useValidChallenges();

    const navigationTabs = React.useMemo(() => navigationTabsScheme(), []);

    React.useEffect(() => {
        const foundTabId = navigationTabs.find((tab) => {
            return pathname.includes(tab.link);
        })?.id;

        if (foundTabId) {
            setValue((currentTabId) => {
                if (currentTabId === foundTabId) return currentTabId;
                return foundTabId;
            });
            return;
        }
        setValue(undefined);
    }, [pathname, navigationTabs]);

    const handleOnTabChange = (tabId: BottomNavigationIds) => {
        setValue(tabId);
        const tab = navigationTabs.find((tab) => tab.id === tabId);
        if (tab) {
            if (tab.id === BottomNavigationIds.HOME || tab.id === BottomNavigationIds.POSITIONS) {
                const hasChallenges = validChallenges.length > 0;
                if (!hasChallenges) {
                    history.push(`/${Pages.PROP}/${Pages.PROP__MY_CHALLENGES}`);
                    return;
                }
            }
            history.push(tab.link);
        }
    };

    const { t } = useTranslation('mobileNavigation');

    return (
        <BottomNavigation
            value={value}
            onChange={(event, newValue) => handleOnTabChange(newValue)}
            showLabels
            className={classes.BottomNavigation_root}
        >
            {navigationTabs.map((nav) => (
                <BottomNavigationAction
                    value={nav.id}
                    key={`mobile_nav_${nav.labelT}`}
                    label={t(nav.labelT)}
                    icon={value === nav.id ? <Icon name={nav.selectedIcon} /> : <Icon name={nav.icon} />}
                    classes={{
                        root: nav.id === BottomNavigationIds.PROP__NEW_CHALLENGES ? classes.propNewChallengesTab : '',
                        wrapper: classes.mui_wrapper,
                        label: classes.label,
                    }}
                />
            ))}
        </BottomNavigation>
    );
};

export default connect(mapStateToProps)(React.memo(MobileBottomNavigation));
