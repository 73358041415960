import { useCallback } from 'react';
import useSelectedPlanAndSelectedChallenge from './useSelectedPlanAndSelectedChallenge';
import { Pages } from 'interfaces/main';
import { useHistory } from 'react-router-dom';
import { PropChallenge, PropPlan } from 'services/generatedClientFromSwagger';

export type RedirectPlanRetryToCheckoutProps = {
    challenge?: PropChallenge;
    plan?: PropPlan;
};
export type RedirectPlanRetryToCheckoutState = {
    handleSelectedPlanRedirectToCheckout: () => void;
    handlePlanRedirectToCheckout: () => void;
    selectedChallenge: PropChallenge;
    selectedPlan: PropPlan;
};

const useRedirectPlanRetryToCheckout = ({
    challenge,
    plan,
}: RedirectPlanRetryToCheckoutProps = {}): RedirectPlanRetryToCheckoutState => {
    const { selectedChallenge, selectedPlan }: { selectedChallenge: PropChallenge; selectedPlan: PropPlan } =
        useSelectedPlanAndSelectedChallenge();

    const history = useHistory();

    const handleSelectedPlanRedirectToCheckout = useCallback(() => {
        history.push(`/${Pages.PROP}/${Pages.PROP__CHECKOUT}?retryPlanId=${selectedPlan?.pPlan?.planId}`);
    }, [history, selectedPlan?.pPlan?.planId]);

    const handlePlanRedirectToCheckout = useCallback(() => {
        history.push(`/${Pages.PROP}/${Pages.PROP__CHECKOUT}?retryPlanId=${plan?.pPlan?.planId}`);
    }, [history, plan?.pPlan?.planId]);

    return { handlePlanRedirectToCheckout, handleSelectedPlanRedirectToCheckout, selectedChallenge, selectedPlan };
};

export default useRedirectPlanRetryToCheckout;
