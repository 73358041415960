import { useTranslation } from 'react-i18next';
import { propStatusStyles } from '../styles/prop-status.styles';
import React from 'react';
import { PropChallengeStatusEnumType, PropChallengeStatusEnum } from 'helpers/challengeStatus';

interface PropStatusHookProps {
    challengeStatus?: PropChallengeStatusEnumType;
    chip?: boolean;
}
export const usePropStatusHook = (props: PropStatusHookProps) => {
    const classes = propStatusStyles();
    const { t } = useTranslation('prop');

    const getStatus = React.useCallback((cbProps: PropStatusHookProps) => {
        const { challengeStatus } = cbProps;
        const status = challengeStatus;
        return status;
    }, []);

    const statusClassCallback = React.useCallback(
        (cbProps: PropStatusHookProps) => {
            const { chip } = cbProps;
            const status = getStatus(cbProps);
            switch (status) {
                case PropChallengeStatusEnum.NotStarted:
                case PropChallengeStatusEnum.Draft:
                    return chip ? classes.statusChipPending : classes.statusPending;
                case PropChallengeStatusEnum.Active:
                    return chip ? classes.statusChipActive : classes.statusActive;
                case PropChallengeStatusEnum.HitEquityTarget:
                    return chip ? classes.statusChipPassed : classes.statusPassed;
                default:
                    return chip ? classes.statusChipEnded : classes.statusEnded;
            }
        },
        [
            classes.statusActive,
            classes.statusChipActive,
            classes.statusChipEnded,
            classes.statusChipPassed,
            classes.statusChipPending,
            classes.statusEnded,
            classes.statusPassed,
            classes.statusPending,
            getStatus,
        ]
    );

    const statusTermCallback = React.useCallback(
        (cbProps: PropStatusHookProps) => {
            const status = getStatus(cbProps);
            if (status) {
                switch (status) {
                    case PropChallengeStatusEnum.NotStarted:
                    case PropChallengeStatusEnum.Draft:
                        return t('card.pending');
                    case PropChallengeStatusEnum.Active:
                        return t('card.active');
                    case PropChallengeStatusEnum.HitEquityTarget:
                        return t('card.passed');
                    default:
                        return t('card.failed');
                }
            }
        },
        [getStatus, t]
    );

    const statusClass = React.useMemo(() => {
        return statusClassCallback(props);
    }, [props, statusClassCallback]);

    const statusTerm = React.useMemo(() => {
        return statusTermCallback(props);
    }, [props, statusTermCallback]);

    const challengeStatusTerm = React.useMemo(() => {
        return statusTermCallback(props);
    }, [props, statusTermCallback]);

    return {
        statusClass,
        statusTerm,
        statusClassCallback,
        statusTermCallback,
        classes,
        getStatus,
        challengeStatusTerm,
    };
};
