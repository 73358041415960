import Loader from '../Loader';
import React from 'react';
interface Props {
    withoutLoader?: boolean;
}

const SplashLoaderFullScreen = (props: Props) => {
    const { withoutLoader = false } = props;
    return (
        <div className="splashScreenColor">
            <div className="splashScreenLogo"></div>
            {!withoutLoader && <Loader />}
        </div>
    );
};

export default SplashLoaderFullScreen;
