import { createSelector } from 'reselect';
import { RootState } from '../index';

const paymentReducerSelector = (state: RootState) => state.payment;

export const payment_depositTabs = createSelector(paymentReducerSelector, (payment) => payment.depositTabs);

export const payment_depositTabsLoading = createSelector(
    paymentReducerSelector,
    (payment) => payment.depositTabsLoading
);

export const payment_freeTrialLoader = createSelector(paymentReducerSelector, (payment) => payment.freeTrialLoader);

export const payment_fullDiscountPlanLoader = createSelector(
    paymentReducerSelector,
    (payment) => payment.fullDiscountPlanLoader
);

export const payment_depositType = createSelector(paymentReducerSelector, (payment) => payment.depositType);

export const payment_depositAmount = createSelector(paymentReducerSelector, (payment) => payment.depositAmount);

export const payment_depositIframeLoading = createSelector(
    paymentReducerSelector,
    (payment) => payment.depositIframeLoading
);

export const payment_depositIframeError = createSelector(
    paymentReducerSelector,
    (payment) => payment.depositIframeError
);

export const payment_depositIframe = createSelector(paymentReducerSelector, (payment) => payment.depositIframe);
export const payment_depositWallets = createSelector(paymentReducerSelector, (payment) => payment.depositWallets);

export const payment_depositResult = createSelector(paymentReducerSelector, (payment) => payment.depositResult);

export const payment_withdrawLoading = createSelector(paymentReducerSelector, (payment) => payment.withdrawLoading);
export const payment_transferBetweenAccountsLoading = createSelector(
    paymentReducerSelector,
    (payment) => payment.transferBetweenAccountsLoading
);
export const payment_transferBetweenAccountsResult = createSelector(
    paymentReducerSelector,
    (payment) => payment.transferBetweenAccountsResult
);

export const payment_withdrawResult = createSelector(paymentReducerSelector, (payment) => payment.withdrawResult);

export const payment_cancelWithdrawStatus = createSelector(paymentReducerSelector, (payment) => payment.cancelWithdraw);

export const payment_cryptoWalletsLoading = createSelector(
    paymentReducerSelector,
    (payment) => payment.cryptoWalletsLoading
);

export const payment_cryptoWallets = createSelector(paymentReducerSelector, (payment) => payment.cryptoWallets);

export const payment_referenceNumber = createSelector(paymentReducerSelector, (payment) => payment.referenceId);
export const payment_referenceNumberLoading = createSelector(
    paymentReducerSelector,
    (payment) => payment.referenceIdLoading
);

export const payment_challengeUpdated = createSelector(paymentReducerSelector, (payment) => payment.challengeUpdated);
