import { createMuiTheme, Direction, ThemeOptions as ThemeOptionsMUI } from '@material-ui/core';

import typography from 'muiTheme/config/typography';
import breakpoints from 'muiTheme/config/breakpoints';
import overrides from 'muiTheme/ui/overrides';
import { ThemeOptions } from 'interfaces/main';
import config from 'config/common';

export interface CreateColorVariantsValue {
    25: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
}

export const grayVariants: CreateColorVariantsValue = {
    25: '#FCFCFD',
    50: '#F9FAFB',
    100: '#F2F4F7',
    200: '#EAECF0',
    300: '#D0D5DD',
    400: '#98A2B3',
    500: '#667085',
    600: '#475467',
    700: '#344054',
    800: '#1D2939',
    900: '#101828',
};

export const grayVariantsDark: CreateColorVariantsValue = {
    900: '#FCFCFD',
    800: '#F9FAFB',
    700: '#F2F4F7',
    600: '#EAECF0',
    500: '#D0D5DD',
    400: '#98A2B3',
    300: '#667085',
    200: '#475467',
    100: '#344054',
    50: '#1D2939',
    25: '#101828',
};

export const errorVariants: CreateColorVariantsValue = {
    25: '#FFFBFA',
    50: '#FEF3F2',
    100: '#FEE4E2',
    200: '#FECDCA',
    300: '#FDA29B',
    400: '#F97066',
    500: '#F04438',
    600: '#D92D20',
    700: '#B42318',
    800: '#912018',
    900: '#7A271A',
};

export const errorVariantsDark: CreateColorVariantsValue = {
    25: '#FFFBFA',
    50: '#FECDCA', // 200
    100: '#FEE4E2',
    200: '#F97066', // 400
    300: '#FDA29B',
    400: '#F97066',
    500: '#F04438',
    600: '#D92D20',
    700: '#7A271A', // 900
    800: '#912018',
    900: '#7A271A',
};

export const successVariants: CreateColorVariantsValue = {
    25: '#F6FEF9',
    50: '#ECFDF3',
    100: '#D1FADF',
    200: '#A6F4C5',
    300: '#6CE9A6',
    400: '#32D583',
    500: '#12B76A',
    600: '#039855',
    700: '#027A48',
    800: '#05603A',
    900: '#054F31',
};

export const successVariantsDark: CreateColorVariantsValue = {
    25: '#F6FEF9',
    50: '#A6F4C5', // 200
    100: '#D1FADF',
    200: '#32D583', // 400
    300: '#6CE9A6',
    400: '#32D583',
    500: '#12B76A',
    600: '#039855',
    700: '#027A48',
    800: '#05603A',
    900: '#054F31',
};

export const warningVariants: CreateColorVariantsValue = {
    25: '#FFFCF5',
    50: '#FFFAEB',
    100: '#FEF0C7',
    200: '#FEDF89',
    300: '#FEC84B',
    400: '#FDB022',
    500: '#F79009',
    600: '#DC6803',
    700: '#B54708',
    800: '#93370D',
    900: '#7A2E0E',
};

export const warningVariantsDark: CreateColorVariantsValue = {
    25: '#FFFCF5',
    50: '#FFFAEB',
    100: '#FEF0C7',
    200: '#FEDF89',
    300: '#FEC84B',
    400: '#FDB022',
    500: '#F79009',
    600: '#F79009',
    700: '#DC6803',
    800: '#93370D',
    900: '#7A2E0E',
};

export const blueVariants: CreateColorVariantsValue = {
    25: '#F5FAFF',
    50: '#EFF8FF',
    100: '#D1E9FF',
    200: '#B2DDFF',
    300: '#84CAFF',
    400: '#53B1FD',
    500: '#2E90FA',
    600: '#1570EF',
    700: '#175CD3',
    800: '#1849A9',
    900: '#194185',
};

///-----------------------Dmitriy----------------------------------////
export const mossVariants: CreateColorVariantsValue = {
    25: '#FAFDF7',
    50: '#F5FBEE',
    100: '#E6F4D7',
    200: '#CEEAB0',
    300: '#ACDC79',
    400: '#86CB3C',
    500: '#669F2A',
    600: '#4F7A21',
    700: '#3F621A',
    800: '#335015',
    900: '#2B4212',
};

export const mossVariantsDark: CreateColorVariantsValue = {
    25: '#FAFDF7',
    50: '#F5FBEE',
    100: '#E6F4D7',
    200: '#CEEAB0',
    300: '#ACDC79',
    400: '#86CB3C',
    500: '#669F2A',
    600: '#4F7A21',
    700: '#3F621A',
    800: '#335015',
    900: '#2B4212',
};

export const greenLightVariants: CreateColorVariantsValue = {
    25: '#FAFEF5',
    50: '#F3FEE7',
    100: '#E4FBCC',
    200: '#D0F8AB',
    300: '#A6EF67',
    400: '#85E13A',
    500: '#66C61C',
    600: '#4CA30D',
    700: '#3B7C0F',
    800: '#326212',
    900: '#2B5314',
};

export const greenLightVariantsDark: CreateColorVariantsValue = {
    25: '#FAFEF5',
    50: '#F3FEE7',
    100: '#E4FBCC',
    200: '#D0F8AB',
    300: '#A6EF67',
    400: '#85E13A',
    500: '#66C61C',
    600: '#4CA30D',
    700: '#3B7C0F',
    800: '#326212',
    900: '#2B5314',
};

export const greenVariants: CreateColorVariantsValue = {
    25: '#F6FEF9',
    50: '#EDFCF2',
    100: '#D3F8DF',
    200: '#AAF0C4',
    300: '#73E2A3',
    400: '#3CCB7F',
    500: '#16B364',
    600: '#099250',
    700: '#087443',
    800: '#095C37',
    900: '#084C2E',
};

export const greenVariantsDark: CreateColorVariantsValue = {
    25: '#F6FEF9',
    50: '#EDFCF2',
    100: '#D3F8DF',
    200: '#AAF0C4',
    300: '#73E2A3',
    400: '#3CCB7F',
    500: '#16B364',
    600: '#099250',
    700: '#087443',
    800: '#095C37',
    900: '#084C2E',
};

export const tealVariants: CreateColorVariantsValue = {
    25: '#F6FEFC',
    50: '#F0FDF9',
    100: '#CCFBEF',
    200: '#99F6E0',
    300: '#5FE9D0',
    400: '#2ED3B7',
    500: '#15B79E',
    600: '#0E9384',
    700: '#107569',
    800: '#125D56',
    900: '#134E48',
};

export const tealVariantsDark: CreateColorVariantsValue = {
    25: '#F6FEFC',
    50: '#F0FDF9',
    100: '#CCFBEF',
    200: '#99F6E0',
    300: '#5FE9D0',
    400: '#2ED3B7',
    500: '#15B79E',
    600: '#0E9384',
    700: '#107569',
    800: '#125D56',
    900: '#134E48',
};

export const cyanVariants: CreateColorVariantsValue = {
    25: '#F5FEFF',
    50: '#ECFDFF',
    100: '#CFF9FE',
    200: '#A5F0FC',
    300: '#67E3F9',
    400: '#22CCEE',
    500: '#06AED4',
    600: '#088AB2',
    700: '#0E7090',
    800: '#155B75',
    900: '#164C63',
};

export const cyanVariantsDark: CreateColorVariantsValue = {
    25: '#F5FEFF',
    50: '#ECFDFF',
    100: '#CFF9FE',
    200: '#A5F0FC',
    300: '#67E3F9',
    400: '#22CCEE',
    500: '#06AED4',
    600: '#088AB2',
    700: '#0E7090',
    800: '#155B75',
    900: '#164C63',
};

export const blueLightVariants: CreateColorVariantsValue = {
    25: '#F5FBFF',
    50: '#F0F9FF',
    100: '#E0F2FE',
    200: '#B9E6FE',
    300: '#7CD4FD',
    400: '#36BFFA',
    500: '#0BA5EC',
    600: '#0086C9',
    700: '#026AA2',
    800: '#065986',
    900: '#0B4A6F',
};

export const blueLightVariantsDark: CreateColorVariantsValue = {
    25: '#F5FBFF',
    50: '#F0F9FF',
    100: '#E0F2FE',
    200: '#B9E6FE',
    300: '#7CD4FD',
    400: '#36BFFA',
    500: '#0BA5EC',
    600: '#0086C9',
    700: '#026AA2',
    800: '#065986',
    900: '#0B4A6F',
};

export const blueDarkVariants: CreateColorVariantsValue = {
    25: '#F5F8FF',
    50: '#EFF4FF',
    100: '#D1E0FF',
    200: '#B2CCFF',
    300: '#84ADFF',
    400: '#528BFF',
    500: '#2970FF',
    600: '#2970FF',
    700: '#004EEB',
    800: '#0040C1',
    900: '#00359E',
};

export const blueDarkVariantsDark: CreateColorVariantsValue = {
    25: '#F5F8FF',
    50: '#EFF4FF',
    100: '#D1E0FF',
    200: '#B2CCFF',
    300: '#84ADFF',
    400: '#528BFF',
    500: '#2970FF',
    600: '#2970FF',
    700: '#004EEB',
    800: '#0040C1',
    900: '#00359E',
};

export const indigoVariants: CreateColorVariantsValue = {
    25: '#F5F8FF',
    50: '#EEF4FF',
    100: '#E0EAFF',
    200: '#C7D7FE',
    300: '#A4BCFD',
    400: '#8098F9',
    500: '#6172F3',
    600: '#444CE7',
    700: '#3538CD',
    800: '#2D31A6',
    900: '#2D3282',
};

export const indigoVariantsDark: CreateColorVariantsValue = {
    25: '#F5F8FF',
    50: '#EEF4FF',
    100: '#E0EAFF',
    200: '#C7D7FE',
    300: '#A4BCFD',
    400: '#8098F9',
    500: '#6172F3',
    600: '#444CE7',
    700: '#3538CD',
    800: '#2D31A6',
    900: '#2D3282',
};

export const violetVariants: CreateColorVariantsValue = {
    25: '#FBFAFF',
    50: '#F5F3FF',
    100: '#ECE9FE',
    200: '#DDD6FE',
    300: '#C3B5FD',
    400: '#A48AFB',
    500: '#875BF7',
    600: '#7839EE',
    700: '#6927DA',
    800: '#5720B7',
    900: '#491C96',
};

export const violetVariantsDark: CreateColorVariantsValue = {
    25: '#FBFAFF',
    50: '#F5F3FF',
    100: '#ECE9FE',
    200: '#DDD6FE',
    300: '#C3B5FD',
    400: '#A48AFB',
    500: '#875BF7',
    600: '#7839EE',
    700: '#6927DA',
    800: '#5720B7',
    900: '#491C96',
};

export const purpleVariants: CreateColorVariantsValue = {
    25: '#FCFAFF',
    50: '#F4F3FF',
    100: '#EBE9FE',
    200: '#D9D6FE',
    300: '#D6BBFB',
    400: '#9B8AFB',
    500: '#7A5AF8',
    600: '#6938EF',
    700: '#6941C6',
    800: '#4A1FB8',
    900: '#3E1C96',
};

export const purpleVariantsDark: CreateColorVariantsValue = {
    25: '#FAFAFF',
    50: '#F4F3FF',
    100: '#EBE9FE',
    200: '#D9D6FE',
    300: '#9E77ED',
    400: '#9B8AFB',
    500: '#7A5AF8',
    600: '#6938EF',
    700: '#9E77ED',
    800: '#4A1FB8',
    900: '#3E1C96',
};

export const fuchsiaVariants: CreateColorVariantsValue = {
    25: '#FEFAFF',
    50: '#FDF4FF',
    100: '#FBE8FF',
    200: '#F6D0FE',
    300: '#EEAAFD',
    400: '#E478FA',
    500: '#D444F1',
    600: '#BA24D5',
    700: '#9F1AB1',
    800: '#821890',
    900: '#6F1877',
};

export const fuchsiaVariantsDark: CreateColorVariantsValue = {
    25: '#FEFAFF',
    50: '#FDF4FF',
    100: '#FBE8FF',
    200: '#F6D0FE',
    300: '#EEAAFD',
    400: '#E478FA',
    500: '#D444F1',
    600: '#BA24D5',
    700: '#9F1AB1',
    800: '#821890',
    900: '#6F1877',
};

export const pinkVariants: CreateColorVariantsValue = {
    25: '#FEF6FB',
    50: '#FDF2FA',
    100: '#FCE7F6',
    200: '#FCCEEE',
    300: '#FAA7E0',
    400: '#F670C7',
    500: '#EE46BC',
    600: '#DD2590',
    700: '#C11574',
    800: '#9E165F',
    900: '#851651',
};

export const pinkVariantsDark: CreateColorVariantsValue = {
    25: '#FEF6FB',
    50: '#FDF2FA',
    100: '#FCE7F6',
    200: '#FCCEEE',
    300: '#FAA7E0',
    400: '#F670C7',
    500: '#EE46BC',
    600: '#DD2590',
    700: '#C11574',
    800: '#9E165F',
    900: '#851651',
};

export const roseVariants: CreateColorVariantsValue = {
    25: '#FFF5F6',
    50: '#FFF1F3',
    100: '#FFE4E8',
    200: '#FECDD6',
    300: '#FEA3B4',
    400: '#FD6F8E',
    500: '#F63D68',
    600: '#E31B54',
    700: '#C01048',
    800: '#A11043',
    900: '#89123E',
};

export const roseVariantsDark: CreateColorVariantsValue = {
    25: '#FFF5F6',
    50: '#FFF1F3',
    100: '#FFE4E8',
    200: '#FECDD6',
    300: '#FEA3B4',
    400: '#FD6F8E',
    500: '#F63D68',
    600: '#E31B54',
    700: '#C01048',
    800: '#A11043',
    900: '#89123E',
};

export const orangeDarkVariants: CreateColorVariantsValue = {
    25: '#FFF9F5',
    50: '#FFF4ED',
    100: '#FFE6D5',
    200: '#FFD6AE',
    300: '#FF9C66',
    400: '#FF692E',
    500: '#FF4405',
    600: '#E62E05',
    700: '#BC1B06',
    800: '#97180C',
    900: '#771A0D',
};

export const orangeDarkVariantsDark: CreateColorVariantsValue = {
    25: '#FFF9F5',
    50: '#FFF4ED',
    100: '#FFE6D5',
    200: '#FFD6AE',
    300: '#FF9C66',
    400: '#FF692E',
    500: '#FF4405',
    600: '#E62E05',
    700: '#BC1B06',
    800: '#97180C',
    900: '#771A0D',
};

export const orangeVariants: CreateColorVariantsValue = {
    25: '#FEFAF5',
    50: '#FEF6EE',
    100: '#FDEAD7',
    200: '#F9DBAF',
    300: '#F7B27A',
    400: '#F38744',
    500: '#EF6820',
    600: '#E04F16',
    700: '#B93815',
    800: '#932F19',
    900: '#772917',
};

export const orangeVariantsDark: CreateColorVariantsValue = {
    25: '#FEFAF5',
    50: '#FEF6EE',
    100: '#FDEAD7',
    200: '#F9DBAF',
    300: '#F7B27A',
    400: '#F38744',
    500: '#EF6820',
    600: '#E04F16',
    700: '#B93815',
    800: '#932F19',
    900: '#772917',
};

export const yellowVariants: CreateColorVariantsValue = {
    25: '#FEFDF0',
    50: '#FEFBE8',
    100: '#FEF7C3',
    200: '#FEEE95',
    300: '#FDE272',
    400: '#FAC515',
    500: '#EAAA08',
    600: '#CA8504',
    700: '#A15C07',
    800: '#854A0E',
    900: '#713B12',
};

export const yellowVariantsDark: CreateColorVariantsValue = {
    25: '#FEFDF0',
    50: '#FEFBE8',
    100: '#FEF7C3',
    200: '#FEEE95',
    300: '#FDE272',
    400: '#FAC515',
    500: '#EAAA08',
    600: '#CA8504',
    700: '#A15C07',
    800: '#854A0E',
    900: '#713B12',
};

export const electricGreenVariants: CreateColorVariantsValue = {
    25: '#2FF996',
    50: '#2FF996',
    100: '#2FF996',
    200: '#2FF996',
    300: '#2FF996',
    400: '#2FF996',
    500: '#2FF996',
    600: '#2FF996',
    700: '#2FF996',
    800: '#2FF996',
    900: '#2FF996',
};

export const electricGreenVariantsDark: CreateColorVariantsValue = {
    25: '#2FF996',
    50: '#2FF996',
    100: '#2FF996',
    200: '#2FF996',
    300: '#2FF996',
    400: '#2FF996',
    500: '#2FF996',
    600: '#2FF996',
    700: '#2FF996',
    800: '#2FF996',
    900: '#2FF996',
};

export const blackVariantsDark: CreateColorVariantsValue = {
    25: '#1a1a1a', // Very light black
    50: '#141414', // Light black
    100: '#0f0f0f', // Medium-light black
    200: '#0a0a0a', // Medium black
    300: '#050505', // Medium-dark black
    400: '#040404', // Dark black
    500: '#030303', // Very dark black
    600: '#020202', // Deeper black
    700: '#010101', // Almost pitch black
    800: '#000000', // Absolute black
    900: '#000000', // Same as absolute black, full intensity
};

export const blackVariants: CreateColorVariantsValue = {
    25: '#1a1a1a', // Very light black
    50: '#141414', // Light black
    100: '#0f0f0f', // Medium-light black
    200: '#0a0a0a', // Medium black
    300: '#050505', // Medium-dark black
    400: '#040404', // Dark black
    500: '#030303', // Very dark black
    600: '#020202', // Deeper black
    700: '#010101', // Almost pitch black
    800: '#000000', // Absolute black
    900: '#000000', // Same as absolute black, full intensity
};

const primaryColors = {
    blue: blueVariants,
    moss: mossVariants,
    greenLight: greenLightVariants,
    green: greenVariants,
    teal: tealVariants,
    cyan: cyanVariants,
    blueLight: blueLightVariants,
    blueDark: blueDarkVariants,
    indigo: indigoVariants,
    violet: violetVariants,
    purple: purpleVariants,
    fuchsia: fuchsiaVariants,
    pink: pinkVariants,
    rose: roseVariants,
    orangeDark: orangeDarkVariants,
    orange: orangeVariants,
    yellow: yellowVariants,
    electricGreen: electricGreenVariants,
    black: blackVariants,
};

const secondaryColors = {
    blue: blueVariants,
    moss: mossVariants,
    greenLight: greenLightVariants,
    green: greenVariants,
    teal: tealVariants,
    cyan: cyanVariants,
    blueLight: blueLightVariants,
    blueDark: blueDarkVariants,
    indigo: indigoVariants,
    violet: violetVariants,
    purple: purpleVariants,
    fuchsia: fuchsiaVariants,
    pink: pinkVariants,
    rose: roseVariants,
    orangeDark: orangeDarkVariants,
    orange: orangeVariants,
    yellow: yellowVariants,
    electricGreen: electricGreenVariants,
    black: blackVariants,
};

const primaryColorRun = primaryColors[config.primaryColor] || primaryColors.blue;
const secondaryColorRun = secondaryColors[config.secondaryColor] || secondaryColors.green;

export interface ColorVariants {
    primaryVariants: CreateColorVariantsValue;
    secondaryVariants: CreateColorVariantsValue;
    errorVariants: CreateColorVariantsValue;
    successVariants: CreateColorVariantsValue;
    warningVariants: CreateColorVariantsValue;
    grayVariants: CreateColorVariantsValue;
    blueVariants: CreateColorVariantsValue;
    mossVariants: CreateColorVariantsValue;
    greenLightVariants: CreateColorVariantsValue;
    greenVariants: CreateColorVariantsValue;
    tealVariants: CreateColorVariantsValue;
    cyanVariants: CreateColorVariantsValue;
    blueLightVariants: CreateColorVariantsValue;
    blueDarkVariants: CreateColorVariantsValue;
    indigoVariants: CreateColorVariantsValue;
    violetVariants: CreateColorVariantsValue;
    purpleVariants: CreateColorVariantsValue;
    fuchsiaVariants: CreateColorVariantsValue;
    pinkVariants: CreateColorVariantsValue;
    roseVariants: CreateColorVariantsValue;
    orangeDarkVariants: CreateColorVariantsValue;
    orangeVariants: CreateColorVariantsValue;
    yellowVariants: CreateColorVariantsValue;
    electricGreenVariants: CreateColorVariantsValue;
    blackVariants: CreateColorVariantsValue;
}

export default (theme: ThemeOptions, direction: Direction) => {
    const colorVariants: ColorVariants = {
        primaryVariants: primaryColorRun,
        secondaryVariants: secondaryColorRun,
        errorVariants: theme === ThemeOptions.DARK ? errorVariantsDark : errorVariants,
        successVariants: theme === ThemeOptions.DARK ? successVariantsDark : successVariants,
        warningVariants: theme === ThemeOptions.DARK ? warningVariantsDark : warningVariants,
        grayVariants: theme === ThemeOptions.DARK ? grayVariantsDark : grayVariants,
        blueVariants: theme === ThemeOptions.DARK ? blueLightVariantsDark : blueVariants,
        mossVariants: theme === ThemeOptions.DARK ? mossVariantsDark : mossVariants,
        greenLightVariants: theme === ThemeOptions.DARK ? greenLightVariantsDark : greenLightVariants,
        greenVariants: theme === ThemeOptions.DARK ? greenVariantsDark : greenVariants,
        tealVariants: theme === ThemeOptions.DARK ? tealVariantsDark : tealVariants,
        cyanVariants: theme === ThemeOptions.DARK ? cyanVariantsDark : cyanVariants,
        blueLightVariants: theme === ThemeOptions.DARK ? blueLightVariantsDark : blueLightVariants,
        blueDarkVariants: theme === ThemeOptions.DARK ? blueDarkVariantsDark : blueDarkVariants,
        indigoVariants: theme === ThemeOptions.DARK ? indigoVariantsDark : indigoVariants,
        violetVariants: theme === ThemeOptions.DARK ? violetVariantsDark : violetVariants,
        purpleVariants: theme === ThemeOptions.DARK ? purpleVariantsDark : purpleVariants,
        fuchsiaVariants: theme === ThemeOptions.DARK ? fuchsiaVariantsDark : fuchsiaVariants,
        pinkVariants: theme === ThemeOptions.DARK ? pinkVariantsDark : pinkVariants,
        roseVariants: theme === ThemeOptions.DARK ? roseVariantsDark : roseVariants,
        orangeDarkVariants: theme === ThemeOptions.DARK ? orangeDarkVariantsDark : orangeDarkVariants,
        orangeVariants: theme === ThemeOptions.DARK ? orangeVariantsDark : orangeVariants,
        yellowVariants: theme === ThemeOptions.DARK ? yellowVariantsDark : yellowVariants,
        electricGreenVariants: theme === ThemeOptions.DARK ? electricGreenVariantsDark : electricGreenVariants,
        blackVariants: theme === ThemeOptions.DARK ? blackVariantsDark : blackVariants,
    };

    const createTheme: ThemeOptionsMUI = {
        overrides,
        palette: {
            type: theme,
            common: { black: '#000', white: '#fff' },
            background: {
                default: theme === ThemeOptions.DARK ? colorVariants.grayVariants[50] : '#ffffff',
                paper: theme === ThemeOptions.DARK ? colorVariants.grayVariants[25] : '#ffffff',
            },
            side_menu: {
                background: theme === ThemeOptions.DARK ? colorVariants.grayVariants[50] : '#ffffff',
                text: colorVariants.grayVariants[700],
            },
            default: {
                main: colorVariants.grayVariants[100],
                light: '#ffffff',
                dark: colorVariants.grayVariants[50],
                contrastText: colorVariants.grayVariants[700],
            },
            primary: {
                main:
                    theme === ThemeOptions.DARK
                        ? colorVariants.primaryVariants[500]
                        : colorVariants.primaryVariants[600],
                contrastText: '#fff',
                ...colorVariants.primaryVariants,
            },
            secondary: {
                main:
                    theme === ThemeOptions.DARK
                        ? colorVariants.secondaryVariants[500]
                        : colorVariants.secondaryVariants[500],
                contrastText: '#fff',
                ...colorVariants.secondaryVariants,
            },
            success: {
                main: colorVariants.successVariants[500],
                contrastText: '#fff',
                ...colorVariants.successVariants,
            },
            error: { main: colorVariants.errorVariants[500], contrastText: '#fff', ...colorVariants.errorVariants },
            warning: { main: colorVariants.warningVariants[500], ...colorVariants.warningVariants },
            text: {
                default: colorVariants.grayVariants[700],
                lightDefault: '#717284',
                primary: colorVariants.grayVariants[700],
                secondary: colorVariants.grayVariants[500],
                disabled: colorVariants.grayVariants[500],
                hint: '#00000061',
            },
            tooltip: {
                background: theme === ThemeOptions.DARK ? colorVariants.grayVariants[100] : '#ffffff',
                text: colorVariants.grayVariants[700],
            },
            disabled: {
                background: colorVariants.grayVariants[100],
                backgroundDark: '#6585a4',
                border: colorVariants.grayVariants[200],
                contrastText: '#fff',
            },
            ...colorVariants,
            featuresFlags: config.featuresFlags,
        },
        typography: typography,
        breakpoints: {
            values: breakpoints,
        },
        spacing: 0.4,
        direction,
    };

    return createMuiTheme(createTheme);
};

declare module '@material-ui/core/styles/createPalette' {
    interface Palette {
        default: Palette['primary'];
        defaultSecondary: Palette['secondary'];
        primaryVariants: CreateColorVariantsValue;
        secondaryVariants: CreateColorVariantsValue;
        successVariants: CreateColorVariantsValue;
        warningVariants: CreateColorVariantsValue;
        errorVariants: CreateColorVariantsValue;
        grayVariants: CreateColorVariantsValue;
        blueVariants: CreateColorVariantsValue;
        mossVariants: CreateColorVariantsValue;
        greenLightVariants: CreateColorVariantsValue;
        greenVariants: CreateColorVariantsValue;
        TealVariants: CreateColorVariantsValue;
        cyanVariants: CreateColorVariantsValue;
        blueLightVariants: CreateColorVariantsValue;
        blueDarkVariants: CreateColorVariantsValue;
        indigoVariants: CreateColorVariantsValue;
        violetVariants: CreateColorVariantsValue;
        purpleVariants: CreateColorVariantsValue;
        fuchsiaVariants: CreateColorVariantsValue;
        pinkVariants: CreateColorVariantsValue;
        roseVariants: CreateColorVariantsValue;
        orangeDarkVariants: CreateColorVariantsValue;
        orangeVariants: CreateColorVariantsValue;
        yellowVariants: CreateColorVariantsValue;
        electricGreenVariants: CreateColorVariantsValue;
        side_menu: {
            background: string;
            text: string;
        };
        tooltip: {
            background: string;
            text: string;
        };
        disabled: {
            background: string;
            backgroundDark: string;
            border: string;
            contrastText: string;
        };
        featuresFlags: {};
    }
    interface PaletteOptions {
        default: PaletteOptions['primary'];
        side_menu: {
            background: string;
            text: string;
        };
        tooltip: {
            background: string;
            text: string;
        };
        disabled: {
            background: string;
            backgroundDark: string;
            border: string;
            contrastText: string;
        };
        featuresFlags: {};
    }
    interface TypeText {
        default: string;
        lightDefault: string;
        defaultSecondary: string;
    }
}
