import React from 'react';
import { Badge, ClickAwayListener, Tooltip } from '@material-ui/core';
import Icon, { IconsType } from 'components/Icon';
import classNames from 'classnames';
import useStyles from './main-notification-btn.styles';
import { isIOS, isRtl, isTablet } from 'helpers/device_helper';
import NotificationTooltip from '../NotificationTooltip';
import { connect } from 'react-redux';
import { MainNotificationBtnProps, mapDispatchToProps, mapStateToProps } from './main-notification-btn.scheme';
import { useHistory } from 'react-router-dom';
import { Pages } from 'interfaces/main';
import { useTranslation } from 'react-i18next';

const MainNotificationBtn: React.FunctionComponent<MainNotificationBtnProps> = (props) => {
    const {
        notification_notifications,
        notification_getAllNotifications,
        user_userInfo,
        user_getUserPushNotificationPermission,
        notification_markNotifications,
    } = props;
    const [menuState, setMenuState] = React.useState<boolean>(false);
    const { t } = useTranslation('notifications');
    const classes = useStyles();

    const history = useHistory();

    React.useEffect(() => {
        if (user_userInfo.id) {
            notification_getAllNotifications();
        }
    }, [notification_getAllNotifications, user_userInfo]);

    const OnBtnClick = () => {
        if (!isIOS() && Notification.permission === 'default') {
            user_getUserPushNotificationPermission();
        }
        if (isTablet()) {
            return setMenuState(!menuState);
        }
        history.push(`/${Pages.NOTIFICATIONS}`);
    };

    const onClickAway = () => {
        if (!menuState) return;
        setMenuState(false);
    };

    return (
        <ClickAwayListener onClickAway={onClickAway}>
            <div>
                <Tooltip
                    arrow
                    open={menuState}
                    classes={{
                        tooltip: classes.toolTipPaper,
                        arrow: classes.toolTipArrow,
                        popper: classes.toolTipPopper,
                        tooltipPlacementBottom: classes.toolTipTop,
                    }}
                    placement="bottom-end"
                    title={
                        <NotificationTooltip
                            markNotifications={notification_markNotifications}
                            closeMenu={() => setMenuState(false)}
                            t={t}
                            notifications={notification_notifications.slice(0, 10)}
                        />
                    }
                >
                    <Badge
                        badgeContent={notification_notifications.filter((item) => !item.markedAt).length}
                        max={9}
                        color="error"
                        anchorOrigin={{ vertical: 'top', horizontal: isRtl() ? 'left' : 'right' }}
                    >
                        <div
                            className={classNames({ [classes.iconBtn]: true, [classes.iconBtn__active]: menuState })}
                            onClick={OnBtnClick}
                        >
                            <Icon name={IconsType.notification} className={classes.notificationIcon} />
                        </div>
                    </Badge>
                </Tooltip>
            </div>
        </ClickAwayListener>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(MainNotificationBtn));
