import classNames from 'classnames';
import Icon, { IconsType } from 'components/Icon';
import Typography from 'components/Typography';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { AccountInfoParsedKycVerificationStatusEnum } from 'services/generatedClientFromSwagger';

type KycStatusChipProps = {
    status?: AccountInfoParsedKycVerificationStatusEnum;
};

const useStyles = makeStyles((theme) => ({
    wrapper__nonVerified: {
        backgroundColor: theme.palette.warning[100],
        color: theme.palette.warning[600],
        width: 'fit-content',
        height: '26px',
        borderRadius: '100px',
        display: 'flex',
        alignItems: 'center',
    },
    wrapper__verified: {
        backgroundColor: theme.palette.greenVariants[100],
        color: theme.palette.greenVariants[600],
    },
    icon: {
        margin: '0px 5.33px',
        stroke: theme.palette.warning[600],
    },
    icon__verified: {
        stroke: theme.palette.greenVariants[600],
    },
    statusButton: {
        paddingInlineEnd: '14px',
    },
}));

export const KycStatusChip: FunctionComponent<KycStatusChipProps> = ({ status }) => {
    const { t } = useTranslation('prop');
    const classes = useStyles();
    const displayVerifyStyles =
        status === AccountInfoParsedKycVerificationStatusEnum.Approved ||
        status === AccountInfoParsedKycVerificationStatusEnum.TraderAgreementAccepted;
    return (
        <div
            className={classNames({
                [classes.wrapper__nonVerified]: true,
                [classes.wrapper__verified]: displayVerifyStyles,
            })}
        >
            <Icon
                className={classNames({
                    [classes.icon]: true,
                    [classes.icon__verified]: displayVerifyStyles,
                })}
                name={IconsType.verified_piqk}
            />
            <Typography className={classes.statusButton} variant="tXsMedium">
                {t(`kycStatus.${status}`)}
            </Typography>
        </div>
    );
};
