import { prop__changeSelectedTpChallenge } from 'features/prop/store/plans/actions';
import { prop_myPlans_getSelectedChallenge } from 'features/prop/store/plans/selectors';
import { PropChallenge } from 'services/generatedClientFromSwagger';

export interface ChallengeHeaderSelectProps {
    shrinkVariant?: boolean;
    challenges: Array<PropChallenge>;
    selectedChallenge: PropChallenge;
    setSelectedChallenge: (tpName: string) => void;
    onSelectHandler?: (tpName: string) => void | Promise<void>;
}

export const mapStateToProps = (state) => ({
    selectedChallenge: prop_myPlans_getSelectedChallenge(state),
});

export const mapDispatchToProps = {
    setSelectedChallenge: prop__changeSelectedTpChallenge,
};
