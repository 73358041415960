import * as React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { history } from 'utils/history';
import store, { RootState } from 'store';
import App from 'App';
import 'assets/styles/styles.scss';
import { config_getEnv } from 'store/config/actions';
import { connect } from 'react-redux';
import { config_loader } from 'store/config/selectors';
import { getAllSearchParamsAsObject } from 'helpers/urlParamaters';
import mixPanel, { mixPanelEventsTypes } from 'helpers/mixPanel';

interface ShellProps {
    config_loader: boolean;
    config_getEnv: () => void;
}

const Shell = ({ config_getEnv, config_loader }: ShellProps) => {
    React.useEffect(() => {
        config_getEnv();
    }, [config_getEnv]);

    React.useEffect(() => {
        mixPanel.init();
        const searchParamsAsObject = getAllSearchParamsAsObject();
        const notAllowedKeys = ['ssotoken', 'wttoken', 'crmtoken'];
        Object.keys(searchParamsAsObject).forEach((key) => {
            if (notAllowedKeys.some((naKey) => naKey === key.toLowerCase())) {
                delete searchParamsAsObject[key];
            }
        });

        mixPanel.track(mixPanelEventsTypes.LANDING_PAGE, searchParamsAsObject);

        const handleAppInstalled = () => {
            mixPanel.track(mixPanelEventsTypes.PWA_INSTALLED);
        };
        window.addEventListener('appinstalled', handleAppInstalled);
        return () => {
            window.removeEventListener('appinstalled', handleAppInstalled);
        };
    }, []);

    return (
        <Router history={history}>
            <App />
        </Router>
    );
};

const mapStateToProps = (state: RootState) => ({
    config_loader: config_loader(state),
});

const mapDispatchToProps = {
    config_getEnv,
};
const ShellWithStore = connect(mapStateToProps, mapDispatchToProps)(Shell);

const Root: React.FunctionComponent = () => {
    return (
        <Provider store={store}>
            <ShellWithStore />
        </Provider>
    );
};

export default Root;
