import { Theme, makeStyles } from '@material-ui/core';
import { IconBoxStylesProps } from './iconBox.scheme';

const getWrapperColors = (theme, { color, variant, size }: IconBoxStylesProps) => {
    return {
        backgroundColor:
            theme.palette.type === 'dark'
                ? theme.palette[`${color}Variants`][600]
                : theme.palette[`${color}Variants`][100],
        ...(variant === 'border'
            ? {
                  border: `${size / 8}px solid ${
                      theme.palette.type === 'dark'
                          ? theme.palette[`${color}Variants`][700]
                          : theme.palette[`${color}Variants`][50]
                  }`,
              }
            : {}),
    };
};

const getColorsByVariant = (theme: Theme, { color }: Pick<IconBoxStylesProps, 'color'>) => {
    return {
        color: theme.palette.type === 'dark' ? '#fff' : theme.palette[`${color}Variants`][600],
    };
};

export const useStyles = makeStyles((theme) => {
    return {
        iconWrapper: (props: IconBoxStylesProps) => {
            const { color, variant, size } = props;
            const wrapperStyles = getWrapperColors(theme, { color, variant, size });
            return {
                ...wrapperStyles,
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: size,
                height: size,
            };
        },
        icon: (props: IconBoxStylesProps) => {
            const { color, size } = props;
            const wrapperStyles = getColorsByVariant(theme, { color });
            return {
                width: size / 2,
                height: size / 2,
                ...wrapperStyles,
            };
        },
    };
});
