/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ResultInfo } from './result-info';

/**
 *
 * @export
 * @interface ValidateDiscountCodeResponse
 */
export class ValidateDiscountCodeResponse {
    /**
     *
     * @type {ResultInfo}
     * @memberof ValidateDiscountCodeResponse
     */
    result?: ResultInfo;
    /**
     *
     * @type {number}
     * @memberof ValidateDiscountCodeResponse
     */
    minimumRequired?: number;
    /**
     *
     * @type {number}
     * @memberof ValidateDiscountCodeResponse
     */
    maximumRequired?: number;
    /**
     *
     * @type {string}
     * @memberof ValidateDiscountCodeResponse
     */
    discountType?: ValidateDiscountCodeResponseDiscountTypeEnum;
    /**
     *
     * @type {string}
     * @memberof ValidateDiscountCodeResponse
     */
    message?: string;
    /**
     *
     * @type {number}
     * @memberof ValidateDiscountCodeResponse
     */
    newOrderAmount?: number;
    /**
     *
     * @type {string}
     * @memberof ValidateDiscountCodeResponse
     */
    discountCodeStatus?: ValidateDiscountCodeResponseDiscountCodeStatusEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum ValidateDiscountCodeResponseDiscountTypeEnum {
    Fixed = 'Fixed',
    Percent = 'Percent',
}
/**
 * @export
 * @enum {string}
 */
export enum ValidateDiscountCodeResponseDiscountCodeStatusEnum {
    NotFound = 'NotFound',
    Valid = 'Valid',
    Invalid = 'Invalid',
    MinimumNotReached = 'MinimumNotReached',
    ManualDeactivated = 'ManualDeactivated',
    Expired = 'Expired',
    TryExceeded = 'TryExceeded',
    MoreThanMaximum = 'MoreThanMaximum',
}
