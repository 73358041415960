import { usePropStatusHook } from './prop-status.hook';
import { useTranslation } from 'react-i18next';
import { useRetryPlanDuration } from 'hooks/useRetryPlanDuration';
import { PropChallenge, PropPlan } from 'services/generatedClientFromSwagger';

export const useEnableRetryLogic = ({ challenge, plan }: { challenge?: PropChallenge; plan?: PropPlan }) => {
    const { t } = useTranslation('prop');

    const { statusTerm } = usePropStatusHook({ challengeStatus: challenge?.challengeStatus });

    const { gapInMilliseconds, isExpirationDateRetryActive } = useRetryPlanDuration({
        endTime: plan?.pPlan?.endTime,
        retryExpirationDays: plan?.pPlan?.retryOptions?.retryExpirationDays,
    });

    return {
        isRetryActive:
            !!challenge?.challengeStatus &&
            statusTerm === t('card.failed') &&
            plan?.pPlan?.retryOptions?.isRetryAvailable &&
            (plan?.pPlan?.retryOptions?.retryCounter ?? 0) < (plan?.pPlan?.retryOptions?.retryLimit ?? 0) &&
            isExpirationDateRetryActive &&
            !plan?.pPlan?.retryOptions?.retryNextPlanReference &&
            (plan?.pPlan?.finalDepositAmount ?? 0) > 0,
        gapInMilliseconds,
    };
};
