import { useSelector } from 'react-redux';
import { usePropStatusHook } from './prop-status.hook';
import { RootState } from 'store/index';
import { useTranslation } from 'react-i18next';
import { PropChallenge, PropPlan } from 'services/generatedClientFromSwagger';

export const useNonRetryOption = () => {
    const { t } = useTranslation('prop');
    const {
        selectedChallenge,
        selectedChallengePlan,
    }: { selectedChallenge: PropChallenge; selectedChallengePlan: PropPlan } = useSelector((state: RootState) => {
        return {
            selectedChallenge: state.prop_myPlans.selectedChallenge,
            selectedChallengePlan: state.prop_myPlans.selectedChallengePlan,
        };
    });
    const { statusTerm } = usePropStatusHook({ challengeStatus: selectedChallenge?.challengeStatus });
    return (
        !!selectedChallenge?.challengeStatus &&
        statusTerm === t('card.failed') &&
        !selectedChallengePlan?.pPlan?.retryOptions?.isRetryAvailable
    );
};
