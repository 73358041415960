/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AccountFeaturesClass } from './account-features-class';
import { DynamicAttributeInfo } from './dynamic-attribute-info';
import { EnvironmentInfo } from './environment-info';
import { GetAccountKycDataResponse } from './get-account-kyc-data-response';
import { MarketingInfo } from './marketing-info';
import { PickListInfo } from './pick-list-info';
import { TradingPlatformAccountInfo } from './trading-platform-account-info';

/**
 *
 * @export
 * @interface AccountInfoParsed
 */
export class AccountInfoParsed {
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_usresidency?: AccountInfoParsedCustomFieldUsresidencyEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_education?: AccountInfoParsedCustomFieldEducationEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_fsca_education?: AccountInfoParsedCustomFieldFscaEducationEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_employmentstatus?: AccountInfoParsedCustomFieldEmploymentstatusEnum;
    /**
     *
     * @type {Array<string>}
     * @memberof AccountInfoParsed
     */
    custom_field_incomesourcetext?: Array<AccountInfoParsedCustomFieldIncomesourcetextEnum>;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_industrycode?: AccountInfoParsedCustomFieldIndustrycodeEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_estimatedannualgrossincome?: AccountInfoParsedCustomFieldEstimatedannualgrossincomeEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_estimatednetworth?: AccountInfoParsedCustomFieldEstimatednetworthEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_anticipatedaccountturnoverperyear?: AccountInfoParsedCustomFieldAnticipatedaccountturnoverperyearEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_tradingexperience?: AccountInfoParsedCustomFieldTradingexperienceEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_securities?: AccountInfoParsedCustomFieldSecuritiesEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_currencies?: AccountInfoParsedCustomFieldCurrenciesEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_futures?: AccountInfoParsedCustomFieldFuturesEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_expectedtradingfrequency?: AccountInfoParsedCustomFieldExpectedtradingfrequencyEnum;
    /**
     *
     * @type {Array<string>}
     * @memberof AccountInfoParsed
     */
    custom_field_levelofinvestmentknowledge?: Array<AccountInfoParsedCustomFieldLevelofinvestmentknowledgeEnum>;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_knowledgequestion1?: AccountInfoParsedCustomFieldKnowledgequestion1Enum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_knowledgequestion2?: AccountInfoParsedCustomFieldKnowledgequestion2Enum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_knowledgequestion3?: AccountInfoParsedCustomFieldKnowledgequestion3Enum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_whatlevelofriskcanyoutolerate?: AccountInfoParsedCustomFieldWhatlevelofriskcanyoutolerateEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_intendedpurposeoftransaction?: AccountInfoParsedCustomFieldIntendedpurposeoftransactionEnum;
    /**
     *
     * @type {Array<string>}
     * @memberof AccountInfoParsed
     */
    custom_field_me_and_family?: Array<AccountInfoParsedCustomFieldMeAndFamilyEnum>;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_gender?: AccountInfoParsedCustomFieldGenderEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_professionalapplicationstatus?: AccountInfoParsedCustomFieldProfessionalapplicationstatusEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_clientcategorisation?: AccountInfoParsedCustomFieldClientcategorisationEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_plantoleavepositionsopen?: AccountInfoParsedCustomFieldPlantoleavepositionsopenEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_attitudetobearloss?: AccountInfoParsedCustomFieldAttitudetobearlossEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_knowledgequestion5?: AccountInfoParsedCustomFieldKnowledgequestion5Enum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_bestdescriptionoffinancialsituation?: AccountInfoParsedCustomFieldBestdescriptionoffinancialsituationEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_anticipatedvolumeoftransactionspermonth?: AccountInfoParsedCustomFieldAnticipatedvolumeoftransactionspermonthEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_knowledgequestion6?: AccountInfoParsedCustomFieldKnowledgequestion6Enum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_primarypurposeoftrading2?: AccountInfoParsedCustomFieldPrimarypurposeoftrading2Enum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_estimatednetworthfsca?: AccountInfoParsedCustomFieldEstimatednetworthfscaEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_whatisyouranticipatedvolume?: AccountInfoParsedCustomFieldWhatisyouranticipatedvolumeEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_whatisyourleveloftradingexperience?: AccountInfoParsedCustomFieldWhatisyourleveloftradingexperienceEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_primarypurposeoftrading?: AccountInfoParsedCustomFieldPrimarypurposeoftradingEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_overthepast2yearswhatwastheextentoftrad?: AccountInfoParsedCustomFieldOverthepast2yearswhatwastheextentoftradEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_pastaverageleverageyouusedfortrading?: AccountInfoParsedCustomFieldPastaverageleverageyouusedfortradingEnum;
    /**
     *
     * @type {Array<string>}
     * @memberof AccountInfoParsed
     */
    custom_field_whatisyoursourceoffinancialincome?: Array<AccountInfoParsedCustomFieldWhatisyoursourceoffinancialincomeEnum>;
    /**
     *
     * @type {Array<string>}
     * @memberof AccountInfoParsed
     */
    custom_field_iormyimmediatefamilymembersare?: Array<AccountInfoParsedCustomFieldIormyimmediatefamilymembersareEnum>;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_whatisyouroccupation?: AccountInfoParsedCustomFieldWhatisyouroccupationEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_solicitation_status?: AccountInfoParsedCustomFieldSolicitationStatusEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_sourceoffinancialincome?: AccountInfoParsedCustomFieldSourceoffinancialincomeEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_regularfinancialcommitments?: AccountInfoParsedCustomFieldRegularfinancialcommitmentsEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_levelofinvestmentknowledgelist?: AccountInfoParsedCustomFieldLevelofinvestmentknowledgelistEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_professionalacademicqualificationexist?: AccountInfoParsedCustomFieldProfessionalacademicqualificationexistEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_professionaloracademicqualification?: AccountInfoParsedCustomFieldProfessionaloracademicqualificationEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_securitiesperqin2years?: AccountInfoParsedCustomFieldSecuritiesperqin2yearsEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_derivativesperqin2years?: AccountInfoParsedCustomFieldDerivativesperqin2yearsEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_forexperqin2years?: AccountInfoParsedCustomFieldForexperqin2yearsEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_averageyearlytransactionsvolume?: AccountInfoParsedCustomFieldAverageyearlytransactionsvolumeEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_averagelevelofleverageusedofpasttrading?: AccountInfoParsedCustomFieldAveragelevelofleverageusedofpasttradingEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    address1?: string | null;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    address2?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    approveReceiveCommercial?: boolean;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    city?: string | null;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    isUsResident?: AccountInfoParsedIsUsResidentEnum;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    firstName?: string | null;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    lastName?: string | null;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    mobileNumber?: string | null;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    phone1AreaCode?: string | null;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    phone1CountryCode?: string | null;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    phone1Number?: string | null;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    phoneAreaCode?: string | null;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    phoneCountryCode?: string | null;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    phoneNumber?: string | null;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    placeOfBirth?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    sendSMS?: boolean;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    state?: string | null;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    zipCode?: string | null;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    country_short?: string;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_dateOfBirth?: string;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_ip?: string;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_taxcode?: string;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_citizenship?: string;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_passportno?: string;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_primarycontactname?: string;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    custom_field_passedappropriatenesstest?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    custom_field_passedsuitabilitytest?: boolean;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_registrationnumber?: string;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_suitabilityupdatedate?: string;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    custom_field_acceptwarningandproceedtrading?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    custom_field_clickedoncancelterminationrequest?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    custom_field_assignforspanishdeclaration?: boolean;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_bepro_step?: string;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_sumsubapplicantid?: string;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_idno?: string;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_passportissuedatenew?: string;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_passportexpirydatenew?: string;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_dateofdischarge?: string;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    custom_field_accepttermsandconditions?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    custom_field_pasttradingexperience?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    custom_field_investmentportfolio?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    custom_field_financialsectorexperience?: boolean;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_requestedleverage?: string;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_rejectdatetime?: string;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    custom_field_isacknowledgerisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    custom_field_approvedaselectiveprofessionalclient?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    custom_field_suppliednecessarydocuments?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    custom_field_rejectwdbycustomerrequest?: boolean;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_promotioncode?: string;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_affiliatecode?: string;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_sourceid?: string;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    custom_field_analystview?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    custom_field_economiccalendar?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    custom_field_featuredideas?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    custom_field_webtv?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    custom_field_educationondemand?: boolean;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_dateoflastlogin?: string;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_language?: string;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_maxDeposit?: string;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_minDeposit?: string;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    custom_field_tcpermissiontype?: boolean;
    /**
     *
     * @type {number}
     * @memberof AccountInfoParsed
     */
    custom_field_sumofdeposits?: number;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_ack_fraud?: string;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_createdon?: string;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_annualincomespecified?: string;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_networthspecified?: string;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_yearlyturnoverspecified?: string;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    custom_field_areyourelatedtocharity?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    custom_field_areyourelatedtocashturnoverretailetc?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    custom_field_areyourelatedtoweaponsproductionsale?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    custom_field_areyourelatedtogamblingbusiness?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    custom_field_areyourelatedtopreciousmetalsstones?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    custom_field_areyourelatedtorealestatedeals?: boolean;
    /**
     *
     * @type {AccountFeaturesClass}
     * @memberof AccountInfoParsed
     */
    accountFeatures?: AccountFeaturesClass;
    /**
     *
     * @type {number}
     * @memberof AccountInfoParsed
     */
    cz_extension_sumAllDeposits?: number;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    custom_field_solicitation_required?: boolean;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_solicitation_response_time?: string;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_amltotalscore?: string;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    custom_field_nationalidentificationnumber?: string;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    custom_field_ispoliticallyexposedperson?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    custom_field_acceptriskdisclosure?: boolean;
    /**
     *
     * @type {number}
     * @memberof AccountInfoParsed
     */
    cz_extension_phoneVerifyStatus?: AccountInfoParsedCzExtensionPhoneVerifyStatusEnum;
    /**
     *
     * @type {number}
     * @memberof AccountInfoParsed
     */
    cz_extension_emailVerifyStatus?: AccountInfoParsedCzExtensionEmailVerifyStatusEnum;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    cz_extension_isVerified?: boolean | null;
    /**
     *
     * @type {GetAccountKycDataResponse}
     * @memberof AccountInfoParsed
     */
    regulationKyc?: GetAccountKycDataResponse;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    hasCcsWallet?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    refundDisclaimerApproved?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    depositDisclaimerApproval?: boolean;
    /**
     *
     * @type {number}
     * @memberof AccountInfoParsed
     */
    maxDepositPerSessionCurrentState?: number;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    acceptTermsAndConditions?: boolean;
    /**
     *
     * @type {PickListInfo}
     * @memberof AccountInfoParsed
     */
    accountType?: PickListInfo | null;
    /**
     *
     * @type {Array<DynamicAttributeInfo>}
     * @memberof AccountInfoParsed
     */
    additionalAttributes?: Array<DynamicAttributeInfo> | null;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    additionalInfo1?: string | null;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    additionalInfo2?: string | null;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    additionalInfo3?: string | null;
    /**
     *
     * @type {PickListInfo}
     * @memberof AccountInfoParsed
     */
    averageTradeSize?: PickListInfo | null;
    /**
     *
     * @type {PickListInfo}
     * @memberof AccountInfoParsed
     */
    cfdTradingExperience?: PickListInfo | null;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    contentType?: string | null;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    country?: string | null;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    countryOfCitizenship?: string | null;
    /**
     *
     * @type {PickListInfo}
     * @memberof AccountInfoParsed
     */
    currenciesTradingExperience?: PickListInfo | null;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    dateOfBirth?: string;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    disabledTradingPlatformAccountExists?: boolean;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    email?: string | null;
    /**
     *
     * @type {EnvironmentInfo}
     * @memberof AccountInfoParsed
     */
    environmentInfo?: EnvironmentInfo | null;
    /**
     *
     * @type {number}
     * @memberof AccountInfoParsed
     */
    estimatedAnnualIncome?: number;
    /**
     *
     * @type {number}
     * @memberof AccountInfoParsed
     */
    estimatedNetWorth?: number;
    /**
     *
     * @type {PickListInfo}
     * @memberof AccountInfoParsed
     */
    futuresTradingExperience?: PickListInfo | null;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    hasDemoExperience?: boolean;
    /**
     *
     * @type {PickListInfo}
     * @memberof AccountInfoParsed
     */
    howDidYouHearAboutUs?: PickListInfo | null;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    id?: string;
    /**
     *
     * @type {MarketingInfo}
     * @memberof AccountInfoParsed
     */
    marketingInfo?: MarketingInfo | null;
    /**
     *
     * @type {PickListInfo}
     * @memberof AccountInfoParsed
     */
    numberOfTimesTradedInPastYear?: PickListInfo | null;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    occupation?: string | null;
    /**
     *
     * @type {PickListInfo}
     * @memberof AccountInfoParsed
     */
    optionsTradingExperience?: PickListInfo | null;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    owningBusinessUnit?: string | null;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    promotionCode?: string | null;
    /**
     *
     * @type {PickListInfo}
     * @memberof AccountInfoParsed
     */
    securitiesTradingExperience?: PickListInfo | null;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    socialSecurityNumber?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    suppliedNecessaryDocuments?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof AccountInfoParsed
     */
    tcPermissions?: boolean;
    /**
     *
     * @type {PickListInfo}
     * @memberof AccountInfoParsed
     */
    title?: PickListInfo | null;
    /**
     *
     * @type {Array<TradingPlatformAccountInfo>}
     * @memberof AccountInfoParsed
     */
    tradingPlatformAccounts?: Array<TradingPlatformAccountInfo> | null;
    /**
     *
     * @type {PickListInfo}
     * @memberof AccountInfoParsed
     */
    typeOfAccount?: PickListInfo | null;
    /**
     *
     * @type {string}
     * @memberof AccountInfoParsed
     */
    kycVerificationStatus?: AccountInfoParsedKycVerificationStatusEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldUsresidencyEnum {
    _0 = '0',
    _1 = '1',
    _2 = '2',
    NeverReplied = 'Never Replied',
    USResident = 'U.S. Resident',
    NotUSResident = 'Not U.S. Resident',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldEducationEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    _5 = '5',
    _6 = '6',
    _7 = '7',
    _8 = '8',
    _9 = '9',
    _10 = '10',
    _11 = '11',
    PrimaryEducation = 'PRIMARY_EDUCATION',
    HighschoolEducation = 'HIGHSCHOOL_EDUCATION',
    TechnicalCollegeVocationalTraining = 'TECHNICAL_COLLEGE_VOCATIONAL_TRAINING',
    BachelorDegree = 'BACHELOR_DEGREE',
    MasterDiplomaDegreeProfessionalTitle = 'MASTER_DIPLOMA_DEGREE_PROFESSIONAL_TITLE',
    PhdDoctorateHolder = 'PHD_DOCTORATE_HOLDER',
    HighSchool = 'HIGH_SCHOOL',
    BachelorsDegree = 'BACHELORS_DEGREE',
    MastersDegree = 'MASTERS_DEGREE',
    Doctorate = 'DOCTORATE',
    Other = 'OTHER',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldFscaEducationEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    _5 = '5',
    _6 = '6',
    _7 = '7',
    PrimaryEducation = 'PRIMARY_EDUCATION',
    TechnicalCollegeVocationalTraining = 'TECHNICAL_COLLEGE_VOCATIONAL_TRAINING',
    HighSchool = 'HIGH_SCHOOL',
    BachelorsDegree = 'BACHELORS_DEGREE',
    MasterDiplomaDegreeProfessionalTitle = 'MASTER_DIPLOMA_DEGREE_PROFESSIONAL_TITLE',
    Doctorate = 'DOCTORATE',
    Other = 'OTHER',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldEmploymentstatusEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    _5 = '5',
    _6 = '6',
    _7 = '7',
    _8 = '8',
    _9 = '9',
    SalariedEmployee = 'SALARIED_EMPLOYEE',
    SelfEmployed = 'SELF_EMPLOYED',
    InvestmentIncomeInvestmentPortfolioPrivatePensionOrSavingsPlan = 'INVESTMENT_INCOME_INVESTMENT_PORTFOLIO_PRIVATE_PENSION_OR_SAVINGS_PLAN',
    GovernmentBenefitsUnemploymentSocialSecurityBenefitsStatePensionEtc = 'GOVERNMENT_BENEFITS_UNEMPLOYMENT_SOCIAL_SECURITY_BENEFITS_STATE_PENSION_ETC',
    Student = 'STUDENT',
    Unemployed = 'UNEMPLOYED',
    Retired = 'RETIRED',
    FulltimeEmployed = 'FULLTIME_EMPLOYED',
    ParttimeEmployed = 'PARTTIME_EMPLOYED',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldIncomesourcetextEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    Salary = 'Salary',
    Savings = 'Savings',
    InvestmentIncome = 'Investment income',
    GovernmentBenefits = 'Government benefits',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldIndustrycodeEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    _5 = '5',
    _6 = '6',
    _7 = '7',
    _8 = '8',
    _9 = '9',
    _10 = '10',
    _11 = '11',
    _12 = '12',
    _13 = '13',
    _14 = '14',
    _15 = '15',
    _16 = '16',
    _17 = '17',
    _18 = '18',
    _19 = '19',
    _20 = '20',
    _21 = '21',
    _22 = '22',
    _23 = '23',
    _24 = '24',
    _25 = '25',
    _26 = '26',
    _27 = '27',
    _28 = '28',
    _29 = '29',
    _30 = '30',
    _31 = '31',
    _32 = '32',
    _33 = '33',
    _34 = '34',
    _35 = '35',
    _36 = '36',
    _37 = '37',
    _38 = '38',
    _39 = '39',
    _40 = '40',
    _41 = '41',
    _42 = '42',
    _43 = '43',
    _44 = '44',
    _45 = '45',
    _46 = '46',
    _47 = '47',
    _48 = '48',
    _49 = '49',
    _50 = '50',
    _51 = '51',
    _52 = '52',
    _53 = '53',
    _54 = '54',
    _55 = '55',
    _56 = '56',
    _57 = '57',
    _58 = '58',
    _59 = '59',
    _60 = '60',
    _61 = '61',
    _62 = '62',
    Accountancy = 'ACCOUNTANCY',
    AdminSecretarial = 'ADMIN_SECRETARIAL',
    Agricultural = 'AGRICULTURAL',
    FinancialServicesBanking = 'FINANCIAL_SERVICES_BANKING',
    Cateringhospitality = 'CATERINGHOSPITALITY',
    CreativeMedia = 'CREATIVE_MEDIA',
    Education = 'EDUCATION',
    EmergencyServices = 'EMERGENCY_SERVICES',
    Engineering = 'ENGINEERING',
    FinancialServicesOther = 'FINANCIAL_SERVICES_OTHER',
    HealthMedicine = 'HEALTH_MEDICINE',
    MilitaryArmedForces = 'MILITARY_ARMED_FORCES',
    HumanResourcesHr = 'HUMAN_RESOURCES_HR',
    FinancialServicesInsurance = 'FINANCIAL_SERVICES_INSURANCE',
    InformationTechnologyIt = 'INFORMATION_TECHNOLOGY_IT',
    Legal = 'LEGAL',
    LeisureEntertainmentTourism = 'LEISURE_ENTERTAINMENT_TOURISM',
    Manufacturing = 'MANUFACTURING',
    MarketingPrAdvertising = 'MARKETING_PR_ADVERTISING',
    Pharmaceuticals = 'PHARMACEUTICALS',
    PropertyConstructionsTrades = 'PROPERTY_CONSTRUCTIONS_TRADES',
    Retail = 'RETAIL',
    Sales = 'SALES',
    SocialCareServices = 'SOCIAL_CARE_SERVICES',
    Telecommunications = 'TELECOMMUNICATIONS',
    Transport = 'TRANSPORT',
    Logistics = 'LOGISTICS',
    Other = 'OTHER',
    Energymining = 'ENERGYMINING',
    Exportimport = 'EXPORTIMPORT',
    GovernmentpublicSector = 'GOVERNMENTPUBLIC_SECTOR',
    Nonprofitcharity = 'NONPROFITCHARITY',
    PreciousMetalsstones = 'PRECIOUS_METALSSTONES',
    Propertyconstructiontrade = 'PROPERTYCONSTRUCTIONTRADE',
    AgricultureAndNonpetrolNaturalResourceExtraction = 'AGRICULTURE_AND_NONPETROL_NATURAL_RESOURCE_EXTRACTION',
    BroadcastingPrintingAndPublishing = 'BROADCASTING_PRINTING_AND_PUBLISHING',
    Brokers = 'BROKERS',
    BuildingSupplyRetail = 'BUILDING_SUPPLY_RETAIL',
    BusinessServices = 'BUSINESS_SERVICES',
    Consulting = 'CONSULTING',
    ConsumerServices = 'CONSUMER_SERVICES',
    DesignDirectionAndCreativeManagement = 'DESIGN_DIRECTION_AND_CREATIVE_MANAGEMENT',
    DistributorsDispatchersAndProcessors = 'DISTRIBUTORS_DISPATCHERS_AND_PROCESSORS',
    DoctorsOfficesAndClinics = 'DOCTORS_OFFICES_AND_CLINICS',
    EatingAndDrinkingPlaces = 'EATING_AND_DRINKING_PLACES',
    EntertainmentRetail = 'ENTERTAINMENT_RETAIL',
    EquipmentRentalAndLeasing = 'EQUIPMENT_RENTAL_AND_LEASING',
    FoodAndTobaccoProcessing = 'FOOD_AND_TOBACCO_PROCESSING',
    InboundCapitalIntensiveProcessing = 'INBOUND_CAPITAL_INTENSIVE_PROCESSING',
    InboundRepairAndServices = 'INBOUND_REPAIR_AND_SERVICES',
    LegalServices = 'LEGAL_SERVICES',
    NondurableMerchandiseRetail = 'NONDURABLE_MERCHANDISE_RETAIL',
    OutboundConsumerService = 'OUTBOUND_CONSUMER_SERVICE',
    PetrochemicalExtractionAndDistribution = 'PETROCHEMICAL_EXTRACTION_AND_DISTRIBUTION',
    ServiceRetail = 'SERVICE_RETAIL',
    SigAffiliations = 'SIG_AFFILIATIONS',
    SocialServices = 'SOCIAL_SERVICES',
    SpecialOutboundTradeContractors = 'SPECIAL_OUTBOUND_TRADE_CONTRACTORS',
    SpecialtyRealty = 'SPECIALTY_REALTY',
    UtilityCreationAndDistribution = 'UTILITY_CREATION_AND_DISTRIBUTION',
    VehicleRetail = 'VEHICLE_RETAIL',
    Wholesale = 'WHOLESALE',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldEstimatedannualgrossincomeEnum {
    _12 = '12',
    _13 = '13',
    _14 = '14',
    _15 = '15',
    _16 = '16',
    _17 = '17',
    _18 = '18',
    _19 = '19',
    _20 = '20',
    _21 = '21',
    _22 = '22',
    _23 = '23',
    _24 = '24',
    _25 = '25',
    _26 = '26',
    _27 = '27',
    _015000 = '0_15000',
    _1500130000 = '15001_30000',
    _3000145000 = '30001_45000',
    _4500170000 = '45001_70000',
    _7000185000 = '70001_85000',
    _85001100000 = '85001_100000',
    _100001150000 = '100001_150000',
    _150001200000 = '150001_200000',
    _200001250000 = '200001_250000',
    _250001 = '>_250001',
    _25000 = '<25000',
    _2500050000 = '_2500050000',
    _50000100000 = '_50000100000',
    _100000250000 = '_100000250000',
    _250000500000 = '_250000500000',
    _500000 = '>500000',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldEstimatednetworthEnum {
    _12 = '12',
    _13 = '13',
    _14 = '14',
    _15 = '15',
    _16 = '16',
    _17 = '17',
    _18 = '18',
    _19 = '19',
    _20 = '20',
    _21 = '21',
    _22 = '22',
    _23 = '23',
    _24 = '24',
    _25 = '25',
    _26 = '26',
    _27 = '27',
    _28 = '28',
    _29 = '29',
    _30 = '30',
    _31 = '31',
    _32 = '32',
    _33 = '33',
    _34 = '34',
    _35 = '35',
    _36 = '36',
    _025000 = '0_25000',
    _2500150000 = '25001_50000',
    _50001100000 = '50001_100000',
    _100001200000 = '100001_200000',
    _200001350000 = '200001_350000',
    _350001500000 = '350001_500000',
    _500001750000 = '500001_750000',
    _9500011000000 = '950001_1000000',
    _1000001 = '>_1000001',
    _015000 = '0_15000',
    _1500130000 = '15001_30000',
    _3000145000 = '30001_45000',
    _4500170000 = '45001_70000',
    _7000185000 = '70001_85000',
    _85001100000 = '85001_100000',
    _100001150000 = '100001_150000',
    _150001200000 = '150001_200000',
    _200001250000 = '200001_250000',
    _250001 = '>_250001',
    _25000 = '<25000',
    _2500050000 = '_2500050000',
    _50000100000 = '_50000100000',
    _100000250000 = '_100000250000',
    _250000500000 = '_250000500000',
    _500000 = '>500000',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldAnticipatedaccountturnoverperyearEnum {
    _1 = '1',
    _23 = '23',
    _24 = '24',
    _25 = '25',
    _26 = '26',
    _27 = '27',
    _28 = '28',
    _29 = '29',
    _30 = '30',
    _31 = '31',
    _32 = '32',
    _33 = '33',
    _34 = '34',
    _35 = '35',
    _2500150000 = '25001_50000',
    _50001100000 = '50001_100000',
    _100001200000 = '100001_200000',
    _200001350000 = '200001_350000',
    _350001500000 = '350001_500000',
    _500001750000 = '500001_750000',
    _9500011000000 = '950001_1000000',
    _1000001 = '>_1000001',
    _5000 = '<5000',
    _500125000 = '_500125000',
    _2500075000 = '_2500075000',
    _75000200000 = '_75000200000',
    _200000500000 = '_200000500000',
    _500000 = '>500000',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldTradingexperienceEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    _5 = '5',
    _6 = '6',
    _7 = '7',
    _8 = '8',
    None = 'NONE',
    _1To3Years = '1_TO_3_YEARS',
    _3To5Years = '3_TO_5_YEARS',
    _5To10Years = '5_TO_10_YEARS',
    MoreThan10Years = 'MORE_THAN_10_YEARS',
    UpTo1Year = 'UP_TO_1_YEAR',
    _1To5Years = '1_TO_5_YEARS',
    MoreThan5Years = 'MORE_THAN_5_YEARS',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldSecuritiesEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    _110Times = '110_TIMES',
    _120Times = '120_TIMES',
    _20Times = '20_TIMES',
    NeverTraded = 'NEVER_TRADED',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldCurrenciesEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    _110Times = '110_TIMES',
    _120Times = '120_TIMES',
    _20Times = '20_TIMES',
    NeverTraded = 'NEVER_TRADED',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldFuturesEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    _110Times = '110_TIMES',
    _120Times = '120_TIMES',
    _20Times = '20_TIMES',
    NeverTraded = 'NEVER_TRADED',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldExpectedtradingfrequencyEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    _5 = '5',
    _6 = '6',
    _7 = '7',
    _8 = '8',
    LessThan4000EurPerQuarter = 'LESS_THAN_4000EUR_PER_QUARTER',
    Between4000EurAnd10000Eur = 'BETWEEN_4000EUR_AND_10000EUR',
    MoreThan10000EurPerQuarter = 'MORE_THAN_10000EUR_PER_QUARTER',
    _5OrLess = '5_OR_LESS',
    _620 = '_620',
    _2040 = '_2040',
    _4060 = '_4060',
    MoreThan60 = 'MORE_THAN_60',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldLevelofinvestmentknowledgeEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    _5 = '5',
    _6 = '6',
    _7 = '7',
    ProfessionalCertificateOrRelevantWorkExperience = 'Professional certificate or relevant work experience',
    DegreeInAFinancialRelatedField = 'Degree in a financial related field',
    TradedWithAnotherBroker = 'Traded with another broker',
    IHaveAttendedTradingCourses = 'I have attended trading courses',
    IHaveNoFinancialKnowledge = 'I have no financial knowledge',
    TradedWithAnotherBrokerOrHaveAttendedTradingCourses = 'Traded with another broker or have attended trading courses',
    IHaveBasicFinancialKnowledge = 'I have basic financial knowledge',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldKnowledgequestion1Enum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    IfTheEquityInYourAccountFallsBelowTheRequiredMarginAMarginCallWillNotLiquidateYourTrades = 'IF_THE_EQUITY_IN_YOUR_ACCOUNT_FALLS_BELOW_THE_REQUIRED_MARGIN_A_MARGIN_CALL_WILL_NOT_LIQUIDATE_YOUR_TRADES',
    OpeningATradeWith100And20XLeverageWillEquateTo2000Investment = 'OPENING_A_TRADE_WITH_100_AND_20X_LEVERAGE_WILL_EQUATE_TO_2000_INVESTMENT',
    IfThePriceOfTheMicrosoftGoesUpThePriceOfYourCfdOnMicrosoftWillGoDown = 'IF_THE_PRICE_OF_THE_MICROSOFT_GOES_UP_THE_PRICE_OF_YOUR_CFD_ON_MICROSOFT_WILL_GO_DOWN',
    WhenMyStopLossIsTriggeredMyPositionWillRemainOpen = 'WHEN_MY_STOP_LOSS_IS_TRIGGERED_MY_POSITION_WILL_REMAIN_OPEN',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldKnowledgequestion2Enum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    _120 = '_120',
    _130 = '_130',
    _110 = '_110',
    _15 = '_15',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldKnowledgequestion3Enum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    BuyOrders = 'BUY_ORDERS',
    StopLossOrders = 'STOP_LOSS_ORDERS',
    SellOrders = 'SELL_ORDERS',
    NoneOfTheAbove = 'NONE_OF_THE_ABOVE',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldWhatlevelofriskcanyoutolerateEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    TakingNoRisks = 'TAKING_NO_RISKS',
    TakingLowRisks = 'TAKING_LOW_RISKS',
    TakingMediumRisks = 'TAKING_MEDIUM_RISKS',
    TakingHighRisks = 'TAKING_HIGH_RISKS',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldIntendedpurposeoftransactionEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    _5 = '5',
    _6 = '6',
    _7 = '7',
    _8 = '8',
    _9 = '9',
    _10 = '10',
    _11 = '11',
    _12 = '12',
    _13 = '13',
    ShortTermReturns = 'SHORT_TERM_RETURNS',
    AdditionalRevenues = 'ADDITIONAL_REVENUES',
    FuturePlanningSaveForChildrenEducationRetirement = 'FUTURE_PLANNING_SAVE_FOR_CHILDREN_EDUCATION_RETIREMENT',
    SavingForHome = 'SAVING_FOR_HOME',
    Speculation = 'SPECULATION',
    Hedging = 'HEDGING',
    ShorttermInvestments = 'SHORTTERM_INVESTMENTS',
    CapitalPreservation = 'CAPITAL_PRESERVATION',
    GrowthOfInvestment = 'GROWTH_OF_INVESTMENT',
    Investment = 'INVESTMENT',
    IntrdayTrading = 'INTRDAY_TRADING',
    RiskManagement = 'RISK_MANAGEMENT',
    Other = 'OTHER',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldMeAndFamilyEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    ADirectorOfAPubliclyTradedCorporationOrShareholderOf10OrMore = 'A director of a publicly traded corporation or Shareholder of 10% or more',
    EmployedByAFinancialInstituteOrBrokerage = 'Employed by a financial institute or brokerage',
    ACurrentOrFormerElectedOrAppointedPublicOfficial = 'A current or former elected or appointed public official',
    NoneOfTheseApplyToMe = 'None of these apply to me',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldGenderEnum {
    _1 = '1',
    _2 = '2',
    Male = 'Male',
    Female = 'Female',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldProfessionalapplicationstatusEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    _5 = '5',
    WebReject = 'Web Reject',
    BoReview = 'BO Review',
    BoApproved = 'BO Approved',
    BoRejected = 'BO Rejected',
    PendingDocs = 'Pending Docs',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldClientcategorisationEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    Retail = 'RETAIL',
    Professional = 'PROFESSIONAL',
    EligibleCounterparty = 'ELIGIBLE_COUNTERPARTY',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldPlantoleavepositionsopenEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    UpTo24Hours = 'Up to 24 hours',
    UpTo2Weeks = 'Up to 2 weeks',
    UpTo1Month = 'Up to 1 month',
    ForSeveralMonths = 'For several months',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldAttitudetobearlossEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    ICareALotICannotLoseAnyPortionOfMyInvestmentCapital = 'I care a lot, I cannot lose any portion of my investment capital',
    IWouldCareButTheLossWouldNotEffectMyFinancialSituation = 'I would care, but the loss would not effect my financial situation.',
    IWouldNotCareIHaveOnlyDepositedFundsIAmWillingAndAbleToLose = 'I would not care, I have only deposited funds I am willing and able to lose',
    IWouldNotCareAtAllTradingInCfdsIsRiskyAndIAmAwareOfThisRisk = 'I would not care at all, trading in CFDs is risky and I am aware of this risk',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldKnowledgequestion5Enum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    RiskMitigationToolsWillGuaranteeYouProfitInTheLongRun = 'Risk mitigation tools will guarantee you profit in the long run',
    RiskMitigationToolsOnlyProtectYouInTheSenseOfMinimisingYourRisk = 'Risk mitigation tools only protect you in the sense of minimising your risk',
    RiskMitigationToolsOnlyMakeYouProfitable = 'Risk mitigation tools only make you profitable',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldBestdescriptionoffinancialsituationEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    INeverSeemToHaveEnoughFundsToCoverMyLivingCosts = 'I never seem to have enough funds to cover my living costs',
    IGetByWithTheMoneyIHaveButHaveDependantsAndOrCannotAffordAdditionalExpenses = 'I get by with the money I have, but have dependants and/or cannot afford additional expenses.',
    IAlwaysHaveEnoughFundsToFulfillMyFinancialNeedsAndDoNotThinkThisWillChangeInTheNearFuture = 'I always have enough funds to fulfill my financial needs and do not think this will change in the near future',
    ICanAffordMyLivingExpensivesThoseOfAnyDependantsAndAlsoHaveAdditionalSavings = 'I can afford my living expensives, those of any dependants and also have additional savings',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldAnticipatedvolumeoftransactionspermonthEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    MoreThan1000Eur = 'More than 1000 EUR',
    Between4000EurAnd10000Eur = 'Between 4000 EUR and 10000 EUR',
    LessThan4000Eur = 'Less than 4000 EUR',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldKnowledgequestion6Enum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    TheyAreSpeculativeComplexAndRiskyFinancialProducts = 'They are speculative, complex, and risky financial products',
    TheyHaveZeroRisk = 'They have zero risk',
    TheyPhysicallyDeliverTheUnderlyingAssetToTheTrader = 'They physically deliver the underlying asset to the trader',
    AllOfTheAbove = 'All of the above',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldPrimarypurposeoftrading2Enum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    PersonalInvestments = 'Personal Investments',
    Speculation = 'Speculation',
    CapitalAppreciation = 'Capital Appreciation',
    Savings = 'Savings',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldEstimatednetworthfscaEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    _010000 = '$0 - $10.000',
    _1000150000 = '$10.001 - $50.000',
    _50001100000 = '$50.001 - $100.000',
    _100000 = '> $100.000',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldWhatisyouranticipatedvolumeEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    MoreThan100000 = 'More than $100.000',
    Between10000And100000 = 'Between $10.000 and $100.000',
    LessThan10000 = 'Less than $10.000',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldWhatisyourleveloftradingexperienceEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    NoExperience = 'No Experience',
    Beginner = 'Beginner',
    Intermediate = 'Intermediate',
    Advance = 'Advance',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldPrimarypurposeoftradingEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    ShortTermReturns = 'Short term returns',
    AdditionalRevenues = 'Additional revenues',
    FuturePlanningSaveForChildrenEducationRetirement = 'Future planning (Save for children, education, retirement)',
    Hedging = 'Hedging',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldOverthepast2yearswhatwastheextentoftradEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    OccasionallyUpTo25TradesPerYear = 'Occasionally (up to 25 trades per year)',
    RegularlyBetween25To100TradesPerYear = 'Regularly (between 25 to 100 trades per year)',
    NeverTraded = 'Never traded',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldPastaverageleverageyouusedfortradingEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    INeverUsedLeverage = 'I never used Leverage',
    LowerThan150 = 'Lower than 1:50',
    Between150To1200 = 'Between 1:50 to 1:200',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldWhatisyoursourceoffinancialincomeEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    _5 = '5',
    Salary = 'Salary',
    Savings = 'Savings',
    InvestmentIncomeInvestmentPortfolioPrivatePensionOrSavingsPlan = 'Investment Income (investment portfolio, Private Pension or Savings Plan)',
    InheritanceGiftSaleOfProperty = 'Inheritance, gift, sale of property',
    GovernmentBenefitsUnemploymentSocialSecurityBenefitsStatePensionEtc = 'Government benefits (unemployment, social security benefits, state pension, etc.)',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldIormyimmediatefamilymembersareEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    _5 = '5',
    _6 = '6',
    IAmAPoliticallyExposedPersonPepOrRelatedToAPep = 'I am a Politically Exposed Person (PEP) or related to a PEP',
    IAmIdentifiedOnASanctionsList = 'I am identified on a sanctions list',
    IHaveBeenConvictedOfACrimeOrHaveAnyOutstandingCriminalChargesAgainstMediate = 'I have been convicted of a crime or have any outstanding criminal charges against mediate',
    ThereIsARelevantAdverseMediaAgainstMe = 'There is a relevant adverse media against me',
    MyBehaviourOrActivityGivenRiseToAnySuspicion = 'My behaviour or activity given rise to any suspicion',
    NoneOfTheseApplyToMe = 'None of these apply to me',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldWhatisyouroccupationEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    _5 = '5',
    _6 = '6',
    _7 = '7',
    _8 = '8',
    _9 = '9',
    _10 = '10',
    _11 = '11',
    _12 = '12',
    _13 = '13',
    _14 = '14',
    _15 = '15',
    _16 = '16',
    _17 = '17',
    _18 = '18',
    _19 = '19',
    _20 = '20',
    _21 = '21',
    _22 = '22',
    _23 = '23',
    _24 = '24',
    _25 = '25',
    _26 = '26',
    _27 = '27',
    _28 = '28',
    _29 = '29',
    _30 = '30',
    _31 = '31',
    _32 = '32',
    _33 = '33',
    _34 = '34',
    _35 = '35',
    _214780000 = '214780000',
    _214780001 = '214780001',
    _214780002 = '214780002',
    _214780003 = '214780003',
    _214780004 = '214780004',
    _214780005 = '214780005',
    _214780006 = '214780006',
    _214780007 = '214780007',
    _214780008 = '214780008',
    _214780009 = '214780009',
    _214780010 = '214780010',
    _214780011 = '214780011',
    Agriculture = 'Agriculture',
    FinancialServicesBanking = 'Financial Services – Banking',
    Accountancy = 'Accountancy',
    AdminSecretarial = 'Admin – Secretarial',
    CateringHospitality = 'Catering – Hospitality',
    CreativeMedia = 'Creative – Media',
    Education = 'Education',
    EmergencyServices = 'Emergency Services',
    Engineering = 'Engineering',
    FinancialServicesOther = 'Financial Services - Other',
    HealthMedicine = 'Health – Medicine',
    MilitaryArmedForces = 'Military - Armed Forces',
    HumanResourcesHr = 'Human Resources (HR)',
    FinancialServicesInsurance = 'Financial Services – Insurance',
    ImportExportAgency = 'Import & export agency',
    InformationTechnologyIt = 'Information technology (IT)',
    Legal = 'Legal',
    LeisureEntertainmentTourism = 'Leisure – Entertainment – Tourism',
    ManufacturingTobaccoHemp = 'Manufacturing - Tobacco / Hemp',
    Manufacturing = 'Manufacturing',
    MarketingPrAdvertising = 'Marketing – PR – Advertising',
    Pharmaceuticals = 'Pharmaceuticals',
    PoliticalActivities = 'Political activities',
    PropertyConstructionsTrades = 'Property – Constructions – Trades',
    PublicStateOwnedEntitiesSoe = 'Public & State Owned Entities (SOE)',
    ReligiousActivities = 'Religious activities',
    Retail = 'Retail',
    RetailWholesaleRepairTradeJewelleryMetalsStones = 'Retail, ​wholesale & repair trade - jewellery, metals &, stones',
    RetailWholesaleRepairTradeGamblingBetting = 'Retail, ​wholesale & repair trade  - gambling & betting',
    Sales = 'Sales',
    SocialCareServices = 'Social Care Services',
    Telecommunications = 'Telecommunications',
    Transport = 'Transport',
    Logistics = 'Logistics',
    Other = 'Other',
    AirlineServices = 'Airline Services',
    Artist = 'Artist',
    BarberHairdresser = 'Barber/ Hairdresser',
    BrokeringServices = 'Brokering Services',
    CarDealer = 'Car Dealer',
    CasinoGamblingOperatorIncludingOnline = 'Casino / Gambling Operator (including online)',
    Charities = 'Charities',
    JewelryDealerInPreciousMetals = 'Jewelry / Dealer in precious metals',
    PoliticsGovernmentLaw = 'Politics / Government / Law',
    PropertyAndRealEstateServices = 'Property and Real Estate Services',
    PubsNightClubsRestaurants = 'Pubs / Night Clubs / Restaurants',
    TaxiServices = 'Taxi Services',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldSolicitationStatusEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    Null = 'Null',
    Confirmed = 'Confirmed',
    Declined = 'Declined',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldSourceoffinancialincomeEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    _5 = '5',
    _6 = '6',
    _7 = '7',
    Investments = 'Investments',
    Pension = 'Pension',
    Property = 'Property',
    Inheritance = 'Inheritance',
    CashDepositsInAnyForm = 'Cash Deposits in any form',
    Savings = 'Savings',
    Salary = 'Salary',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldRegularfinancialcommitmentsEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    _5 = '5',
    Rent = 'Rent',
    Loan = 'Loan',
    MortgagePayments = 'Mortgage Payments',
    Other = 'Other',
    NoRegularFinancialCommitments = 'No regular financial commitments',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldLevelofinvestmentknowledgelistEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    _5 = '5',
    ProfessionalCertificateOrRelevantWorkExperience = 'Professional certificate or relevant work experience',
    DegreeInAFinancialRelatedField = 'Degree in a Financial related field',
    TradedWithAnotherBroker = 'Traded with another broker',
    IHaveAttendedTradingCourses = 'I have attended trading courses',
    IHaveNoFinancialKnowledge = 'I have no financial knowledge',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldProfessionalacademicqualificationexistEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    WorkedForAtLeast3YearsInADirectRelevantRole = 'Worked for at least 3 years in a direct relevant role',
    WorkedForLessThan3YearsInADirectRelevantRole = 'Worked for less than 3 years in a direct relevant role',
    NoNever = 'No, never',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldProfessionaloracademicqualificationEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    _5 = '5',
    _6 = '6',
    _7 = '7',
    _8 = '8',
    _9 = '9',
    _10 = '10',
    _11 = '11',
    _12 = '12',
    _13 = '13',
    _14 = '14',
    _15 = '15',
    _16 = '16',
    _17 = '17',
    _18 = '18',
    _19 = '19',
    Accountant = 'Accountant',
    BackOffice = 'Back Office',
    ChiefExecutiveOfficer = 'Chief Executive Officer',
    ChiefFinancialOfficer = 'Chief Financial Officer',
    ChiefOperationsOfficer = 'Chief Operations Officer',
    ClientRelationshipManager = 'Client Relationship Manager',
    Director = 'Director',
    Economist = 'Economist',
    FundManager = 'Fund Manager',
    InvestmentAdvisor = 'Investment Advisor',
    InvestmentAnalyst = 'Investment Analyst',
    Lawyer = 'Lawyer',
    Operations = 'Operations',
    Other = 'Other',
    PortfolioManager = 'Portfolio Manager',
    ResearchAnalyst = 'Research Analyst',
    Sales = 'Sales',
    Statistician = 'Statistician',
    Trader = 'Trader',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldSecuritiesperqin2yearsEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    FrequentlyOver100Trades = 'FREQUENTLY_OVER_100_TRADES',
    RegularlyBetween2550Trades = 'REGULARLY_BETWEEN_25_50_TRADES',
    OccasionallyUpTo25Trades = 'OCCASIONALLY_UP_TO_25_TRADES',
    NeverOrRarely = 'NEVER_OR_RARELY',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldDerivativesperqin2yearsEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    FrequentlyOver100Trades = 'FREQUENTLY_OVER_100_TRADES',
    RegularlyBetween2550Trades = 'REGULARLY_BETWEEN_25_50_TRADES',
    OccasionallyUpTo25Trades = 'OCCASIONALLY_UP_TO_25_TRADES',
    NeverOrRarely = 'NEVER_OR_RARELY',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldForexperqin2yearsEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    FrequentlyOver100Trades = 'FREQUENTLY_OVER_100_TRADES',
    RegularlyBetween2550Trades = 'REGULARLY_BETWEEN_25_50_TRADES',
    OccasionallyUpTo25Trades = 'OCCASIONALLY_UP_TO_25_TRADES',
    NeverOrRarely = 'NEVER_OR_RARELY',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldAverageyearlytransactionsvolumeEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    _5 = '5',
    Over10000 = 'OVER_10000',
    Between250010000 = 'BETWEEN_2500_10000',
    Between5002500 = 'BETWEEN_500_2500',
    LessThan500 = 'LESS_THAN_500',
    INeverInvested = 'I_NEVER_INVESTED',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCustomFieldAveragelevelofleverageusedofpasttradingEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    _5 = '5',
    MoreThan1200 = 'MORE_THAN_1200',
    Between11001200 = 'BETWEEN_1100_1200',
    Between111150 = 'BETWEEN_111_150',
    UpTo110 = 'UP_TO_110',
    INeverUsedLeverage = 'I_NEVER_USED_LEVERAGE',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedIsUsResidentEnum {
    NeverReplied = 'NeverReplied',
    Yes = 'Yes',
    No = 'No',
    Empty = 'Empty',
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCzExtensionPhoneVerifyStatusEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedCzExtensionEmailVerifyStatusEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
}
/**
 * @export
 * @enum {string}
 */
export enum AccountInfoParsedKycVerificationStatusEnum {
    NotStarted = 'NotStarted',
    DocumentUploaded = 'DocumentUploaded',
    InProgress = 'InProgress',
    Resubmission = 'Resubmission',
    Rejected = 'Rejected',
    Approved = 'Approved',
    TraderAgreementAccepted = 'TraderAgreementAccepted',
}
