import { makeStyles } from '@material-ui/core';
import { newShadows } from 'muiTheme/config/palette';
import { isMobileDevice } from 'helpers/device_helper';
interface Props {
    isOpen?: boolean;
}

export default makeStyles((theme) => ({
    dialogPaper: {
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        overflow: 'hidden',
        borderRadius: '12px',
        boxShadow: newShadows.xl,
        padding: '24px',
        backgroundColor:
            theme.palette.type === 'dark' ? theme.palette.grayVariants[25] : theme.palette.background.default,
        '&&.MuiDialog-paper': {
            margin: '10px',
            maxHeight: 'calc(100% - 120px)',

            ...isMobileDevice({
                margin: '24px',
                maxHeight: 'fill-available',
            }),
        },
    },
    backDrop: { backdropFilter: 'blur(8px)' },
    closeButton: {
        color: theme.palette.grayVariants[500],
        width: '32px',
        height: '32px',
        padding: '10px',
    },
    title: {
        color: theme.palette.grayVariants[900],
        marginBottom: '4px !important',
        ...isMobileDevice({
            marginRight: '50px !important',
            marginLeft: '50px !important',
            textAlign: 'center',
            marginBottom: '8px !important',
        }),
    },
    description: {
        color: theme.palette.grayVariants[600],
        ...isMobileDevice({
            textAlign: 'center',
            marginRight: '50px !important',
            marginLeft: '50px !important',
        }),
    },

    button: {
        height: '48px',
        width: '100%',
    },
    closeIcon: {
        color: theme.palette.type === 'dark' ? theme.palette.grayVariants[300] : theme.palette.grayVariants[500],
    },
    iframe: ({ isOpen = false }: Props) => ({
        paddingTop: isOpen ? '40px' : 0,
        height: isOpen ? '100%' : '',
    }),
}));
