import 'reflect-metadata';
import mixpanel from 'mixpanel-browser';
import { Exclude, Expose, plainToClass, Type } from 'class-transformer';
import config from 'config/common';
import { getSearchParamFromUrl } from './urlParamaters';
import {
    MarketingInfo,
    TradingPlatformAccountBalance,
    TradingPlatformAccountInfo,
} from 'services/generatedClientFromSwagger/models';

const extraCommonDetails = {
    version: config.version,
    namespace: config.namespace,
    siteName: config.siteName,
    siteType: config.siteType,
    isWrapperApp: getSearchParamFromUrl('app') || localStorage.getItem('app') || false,
};

let mixPanelInitial: string;

export enum mixPanelEventsTypes {
    REGISTER_LOCAL = 'registerLocal',
    REGISTER_SOCIAL = 'registerSocial',
    LOGIN = 'login',
    AUTO_LOGIN = 'autoLogin',
    DEPOSIT_IFRAME_URL = 'depositIframeUrl',
    DEPOSIT_PROCESS_DONE = 'depositProcessDone',
    PAGE_CHANGE = 'pageChange',
    LANDING_PAGE = 'landingPage',
    CHANGE_SELECTED_TP = 'changeTradingPlatform',
    WITHDRAW_REQUEST = 'withdrawRequest',
    TRANSFER_BETWEEN_ACCOUNTS_REQUEST = 'transferBetweenAccountsRequest',
    CREATE_NEW_TP = 'createNewTp',
    TP_ACCOUNT_BALANCE = 'tpAccountBalance',
    RATES_CLICK_EVENT = 'ratesClickEvent',
    CLICK_PROMOTION_BUTTON = 'clickPromotionButton',
    WHATS_APP_OPEN = 'openWhatsAppHelper',
    WHATS_APP_HIDE_BTN = 'hideWhatsAppBtn',
    TOUR_GUIDE_START_BTN_CLICK = 'TourGuideStartButtonClick',
    CLICK_ON_T4P_SIGNAL = 'ClickOnT4PSignal',
    CCS_SUBMIT_FOR_TRADE = 'ccsSubmitForTrade',
    DEBUG_WEBTRADER_ERROR = 'debugWebtraderError',
    NETWORK_CONNECTION = 'NetworkConnection',
    SUMSUB_EVENTS = 'SumsubEvents',
    PUSH_NOTIFICATION_PERMISSION_STATUS = 'PushNotificationPermissionStatus',
    PUSH_NOTIFICATION_OPEN_WINDOW = 'PushNotificationOpenWindow',
    PWA_INSTALLED = 'PWA Installed',
    CHAT_BASE_DRAWER_TOGGLE = 'ChatBaseDrawerToggle',
    PLAN_PURCHASE_DEPOSIT = 'planPurchaseDeposit',
    PLAN_PAYOUT_WITHDRAW = 'planPayoutWithdraw',
    PLAN_OUTSOURCE_DEPOSIT = 'planOutsourceDeposit',
    PROP_RETRY_TP_WITH_DISCOUNT = 'Prop_Retry_TP_with_Discount',
    PROP_RETRY_CHECKOUT_BACK_BTN = 'Prop_Retry_Checkout_Back_Btn',
    PROP_RETRY_MY_CHALLENGES = 'Prop_Retry_My_Challenges',
    PROP_RETRY_MODAL_RETRY_NOW = 'Prop_Retry_Modal_Retry_Now',
    PROP_RETRY_MODAL_RETRY_BACK = 'Prop_Retry_Modal_Retry_Back',
    PROP_RETRY_MODAL_RETRY_CLOSE = 'Prop_Retry_Modal_Retry_Close',
    PROP_RETRY_PAYMENT_SUCCESSFUL = 'Prop_Retry_Payment_Successful',
}

type mixPanelEventClasses =
    | MixPanelLocalRegister
    | MixPanelSocialRegister
    | MixPanelLogin
    | MixPanelAutoLogin
    | MixPanelDepositCreateIframe
    | CreateNewTp
    | MixPanelWithdrawRequest
    | TPAccountBalace
    | RatesClickEvent
    | ClickPromotionButton
    | MixPanelCcsSubmitForTrade
    | MixPanelPushNotification
    | MixPanelDebugWebtraderError
    | MixPanelNetworkingMessage
    | MixPanelSumsubEvents
    | MixPaneInstallApp
    | MixPanelChatBaseDrawerToggle
    | MixPanelPlanPurchaseDeposit
    | MixPanelPlanPayoutWithdraw
    | MixPanelPlanOutsourceDeposit
    | MixPanelRetry;

class MixPanelEvents {}

@Exclude()
class MixPaneInstallApp extends MixPanelEvents {}

@Exclude()
class MixPanelSumsubEvents extends MixPanelEvents {
    @Expose()
    type: string;
    @Expose()
    payload: {
        [key: string]: any;
    };
}

@Exclude()
class MixPanelCcsSubmitForTrade extends MixPanelEvents {
    @Expose()
    pair: string;
    @Expose({ name: 'volume' })
    amount: string;
    @Expose()
    price: string;
    @Expose()
    error: string;
}

@Exclude()
class MixPanelPushNotification extends MixPanelEvents {
    @Expose()
    permission_status: string;
}

@Exclude()
class ClickPromotionButton extends MixPanelEvents {
    @Expose()
    type: string;
}

@Exclude()
class RatesClickEvent extends MixPanelEvents {
    @Expose()
    clickOn: string;
    @Expose()
    symbol: string;
    @Expose()
    bid: number;
    @Expose()
    ask: number;
}

@Exclude()
class TPAccountBalace extends MixPanelEvents {
    @Expose()
    tpName: string;
    @Expose()
    balance: number;
}

@Exclude()
class CreateNewTp extends MixPanelEvents {
    @Expose()
    currency: string;
    @Expose()
    type: string;
}

@Exclude()
class MixPanelWithdrawRequest extends MixPanelEvents {
    @Expose()
    tpId: string;
    @Expose()
    amount: string;
    @Expose()
    type: string;
}

@Exclude()
class MixPanelLocalRegister extends MixPanelEvents {
    @Expose()
    firstName: string;
    @Expose()
    lastName: string;
    @Expose()
    promoCode: string;
    @Expose()
    accountType: string;
    @Expose()
    status: boolean;
    @Expose()
    error: string;
}

@Exclude()
class MixPanelSocialRegister extends MixPanelEvents {
    @Expose()
    promoCode: string;
    @Expose()
    accountType: string;
    @Expose()
    socialType: string;
    @Expose()
    status: boolean;
    @Expose()
    error: string;
}

@Exclude()
class MixPanelLogin extends MixPanelEvents {
    @Expose()
    loginType: string;
    @Expose()
    status: boolean;
    @Expose()
    error: string;
}

@Exclude()
class MixPanelAutoLogin extends MixPanelEvents {
    @Expose()
    loginType: string;
    @Expose()
    status: boolean;
}

@Exclude()
class MixPanelDepositCreateIframe extends MixPanelEvents {
    @Expose()
    tpId: string;
    @Expose()
    tpName: string;
    @Expose()
    amount: string;
    @Expose()
    psp: string;
    @Expose()
    currency: string;
    @Expose()
    balance: string;
    @Expose()
    conditions: {};
}

@Exclude()
class MixPanelDepositProcessDone extends MixPanelEvents {
    @Expose()
    status: boolean;
    @Expose()
    transactionId: string;
    @Expose()
    amount: number;
    @Expose()
    currency: string;
}

@Exclude()
class MixPanelChangeTP extends MixPanelEvents {
    @Expose()
    id: string;
    @Expose()
    currency: string;
    @Expose()
    type: string;
}

@Exclude()
class MixPanelUserInfo {
    @Expose()
    firstName: string;
    @Expose()
    lastName: string;
    @Expose({ name: 'dateOfBirth' })
    'Date of birth': string;
    @Expose()
    country: string;
    @Expose({ name: 'owningBusinessUnit' })
    'BU Name': string;
    @Expose()
    tradingPlatformAccounts?: TradingPlatformAccountInfo[];
    @Expose()
    accounts?: { [key: string]: TradingPlatformAccountBalance };
    @Expose()
    tpCount: number;
    @Expose()
    tpAccounts?: any[];
    @Expose()
    marketingInfo?: MarketingInfo;
    @Expose()
    featuresFlags?: Array<string>;
}

@Exclude()
class MixPanelClickOnT4pSignal extends MixPanelEvents {
    @Expose()
    id: string;
    @Expose()
    symbol: string;
    @Expose()
    rating: number;
}

@Exclude()
class MixPanelDebugWebtraderError extends MixPanelEvents {
    @Expose()
    failedToLoadIframe: boolean;
    @Expose()
    iframeUrl: string;
    @Expose()
    renewSSOCount: number;
    @Expose()
    iframeUrlLoading: boolean;
    @Expose()
    iframeReady: boolean;
}

@Exclude()
class MixPanelNetworkingMessage extends MixPanelEvents {
    @Expose()
    connectionStatus: string;
}
@Exclude()
class MixPanelChatBaseDrawerToggle extends MixPanelEvents {
    @Expose()
    action: 'open' | 'close';
}

@Exclude()
class MixPanelPlanPurchaseDeposit extends MixPanelEvents {
    @Expose()
    tpId: string;
    @Expose()
    tpName: string;
    @Expose()
    amount: string;
    @Expose()
    psp: string;
    @Expose()
    currency: string;
    @Expose()
    balance: string;
    @Expose()
    conditions: {};
    @Expose()
    type: string;
}

@Exclude()
class MixPanelPlanPayoutWithdraw extends MixPanelEvents {
    @Expose()
    tpId: string;
    @Expose()
    tpName: string;
    @Expose()
    amount: string;
    @Expose()
    psp: string;
    @Expose()
    currency: string;
    @Expose()
    balance: string;
    @Expose()
    type: string;
}

@Exclude()
class MixPanelPlanOutsourceDeposit extends MixPanelEvents {
    @Expose()
    tpId: string;
    @Expose()
    tpName: string;
    @Expose()
    amount: string;
    @Expose()
    psp: string;
    @Expose()
    currency: string;
    @Expose()
    balance: string;
    @Expose()
    type: string;
}

@Exclude()
class MixPanelRetry extends MixPanelEvents {
    @Expose()
    planType: string;
    @Expose()
    planPriceBeforeDiscount: string;
    @Expose()
    planPriceAfterDiscount: string;
    @Expose()
    discountAmount: string;
    @Expose()
    challengeAmount: string;
    @Expose()
    addOns: string;
    @Expose()
    conditionsValues: string;
}

export class mixPanelEventsPayload {
    @Type(() => MixPanelEvents, {
        discriminator: {
            property: '__type',
            subTypes: [
                {
                    value: MixPanelCcsSubmitForTrade,
                    name: mixPanelEventsTypes.CCS_SUBMIT_FOR_TRADE,
                },
                {
                    value: MixPanelLocalRegister,
                    name: mixPanelEventsTypes.REGISTER_LOCAL,
                },
                {
                    value: MixPanelSocialRegister,
                    name: mixPanelEventsTypes.REGISTER_SOCIAL,
                },
                {
                    value: MixPanelLogin,
                    name: mixPanelEventsTypes.LOGIN,
                },
                {
                    value: MixPanelDepositCreateIframe,
                    name: mixPanelEventsTypes.DEPOSIT_IFRAME_URL,
                },
                {
                    value: MixPanelDepositProcessDone,
                    name: mixPanelEventsTypes.DEPOSIT_PROCESS_DONE,
                },
                {
                    value: MixPanelChangeTP,
                    name: mixPanelEventsTypes.CHANGE_SELECTED_TP,
                },
                {
                    value: MixPanelWithdrawRequest,
                    name: mixPanelEventsTypes.WITHDRAW_REQUEST,
                },
                {
                    value: CreateNewTp,
                    name: mixPanelEventsTypes.CREATE_NEW_TP,
                },
                {
                    value: TPAccountBalace,
                    name: mixPanelEventsTypes.TP_ACCOUNT_BALANCE,
                },
                {
                    value: RatesClickEvent,
                    name: mixPanelEventsTypes.RATES_CLICK_EVENT,
                },
                {
                    value: ClickPromotionButton,
                    name: mixPanelEventsTypes.CLICK_PROMOTION_BUTTON,
                },
                {
                    value: MixPanelAutoLogin,
                    name: mixPanelEventsTypes.AUTO_LOGIN,
                },
                {
                    value: MixPanelClickOnT4pSignal,
                    name: mixPanelEventsTypes.CLICK_ON_T4P_SIGNAL,
                },
                {
                    value: MixPanelPushNotification,
                    name: mixPanelEventsTypes.PUSH_NOTIFICATION_PERMISSION_STATUS,
                },
                {
                    value: MixPanelDebugWebtraderError,
                    name: mixPanelEventsTypes.DEBUG_WEBTRADER_ERROR,
                },
                {
                    value: MixPanelNetworkingMessage,
                    name: mixPanelEventsTypes.NETWORK_CONNECTION,
                },
                {
                    value: MixPanelSumsubEvents,
                    name: mixPanelEventsTypes.SUMSUB_EVENTS,
                },
                {
                    value: MixPaneInstallApp,
                    name: mixPanelEventsTypes.PWA_INSTALLED,
                },
                {
                    value: MixPanelChatBaseDrawerToggle,
                    name: mixPanelEventsTypes.CHAT_BASE_DRAWER_TOGGLE,
                },
                {
                    value: MixPanelPlanPurchaseDeposit,
                    name: mixPanelEventsTypes.PLAN_PURCHASE_DEPOSIT,
                },
                {
                    value: MixPanelPlanPayoutWithdraw,
                    name: mixPanelEventsTypes.PLAN_PAYOUT_WITHDRAW,
                },
                {
                    value: MixPanelPlanOutsourceDeposit,
                    name: mixPanelEventsTypes.PLAN_OUTSOURCE_DEPOSIT,
                },
                {
                    value: MixPanelRetry,
                    name: mixPanelEventsTypes.PROP_RETRY_TP_WITH_DISCOUNT,
                },
                {
                    value: MixPanelRetry,
                    name: mixPanelEventsTypes.PROP_RETRY_CHECKOUT_BACK_BTN,
                },
                {
                    value: MixPanelRetry,
                    name: mixPanelEventsTypes.PROP_RETRY_MY_CHALLENGES,
                },
                {
                    value: MixPanelRetry,
                    name: mixPanelEventsTypes.PROP_RETRY_MODAL_RETRY_NOW,
                },
                {
                    value: MixPanelRetry,
                    name: mixPanelEventsTypes.PROP_RETRY_MODAL_RETRY_BACK,
                },
                {
                    value: MixPanelRetry,
                    name: mixPanelEventsTypes.PROP_RETRY_MODAL_RETRY_CLOSE,
                },
                {
                    value: MixPanelRetry,
                    name: mixPanelEventsTypes.PROP_RETRY_PAYMENT_SUCCESSFUL,
                },
            ],
        },
    })
    payload: mixPanelEventClasses;
}

export const track = (type: mixPanelEventsTypes, payload: any = null) => {
    if (config.featuresFlags['disableMixPanel']) return;
    let data = { payload: {} };
    if (payload) {
        data = plainToClass(mixPanelEventsPayload, { payload: { __type: type, ...payload } });
    }

    getSearchParamFromUrl('app') || localStorage.getItem('app');
    mixpanel.track(type, { ...data.payload, ...extraCommonDetails });
};

export const identify = (accountId: string) => {
    if (config.featuresFlags['disableMixPanel']) return;
    if (mixPanelInitial === accountId) return;
    if (!mixPanelInitial) {
        init(accountId);
    }
    if (!mixPanelInitial) return;

    mixpanel.identify(accountId);
};

export const setPeople = (payload: {}) => {
    if (!mixPanelInitial) return;
    let data = plainToClass(MixPanelUserInfo, payload);

    Object.keys(data).forEach((key) => data[key] === undefined && delete data[key]);

    const tps = data?.tradingPlatformAccounts?.map((tp) => tp.name);

    const tpCount = data?.tradingPlatformAccounts?.length;

    const arrayOfFeaturesFlags = Object.keys(config?.featuresFlags).filter((ff) => {
        return config?.featuresFlags[ff] === true;
    });

    data = {
        ...data,
        ...(tps && { tpAccounts: tps }),
        ...(tpCount && { tpCount }),
        featuresFlags: arrayOfFeaturesFlags,
    };

    if (data.marketingInfo) {
        data = { ...data, ...data.marketingInfo };
        delete data.marketingInfo;
    }

    delete data.accounts;
    delete data.tradingPlatformAccounts;

    mixpanel.people.set(data, (...args) => {
        console.log('mixpanel callback', args);
    });
};

export const init = (accountId?: string) => {
    if (config.featuresFlags['disableMixPanel']) return;
    if (config.mixpanelKey) {
        mixpanel.init(config.mixpanelKey);
        if (accountId) {
            mixPanelInitial = accountId;
        }
    }
};

export default {
    track,
    identify,
    setPeople,
    init,
};
