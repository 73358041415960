import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { user_userInfo } from 'store/user/selectors';
import { Pages } from 'interfaces/main';
import { AccountInfoParsed } from 'services/generatedClientFromSwagger/models';
import config from 'config/common';
import { Trading4ProLevelEnum } from 'store/analytics/interfaces';

export interface TradingAnalysisGuardProps {
    component: any;
    userInfo: AccountInfoParsed;
    guardField?: string;
    guardFeature?: string;
    featureFlagName?: string;
    t4pAnalysisGuard?: boolean;
}

export const tradingAnalysisIsAllowed = (userInfo: AccountInfoParsed, guardFeature?: string) => {
    try {
        if (Boolean(config.featuresFlags['czFeatures']) && guardFeature) {
            return userInfo.accountFeatures?.[guardFeature].enabled || false;
        }
        return false;
    } catch (error) {
        return false;
    }
};

export const getTradingAnalysisStatus = (
    userInfo: AccountInfoParsed,
    guardFeature?: string
): undefined | 'disabled' | 'enabled' => {
    try {
        if (Boolean(config.featuresFlags['czFeatures']) && guardFeature) {
            return userInfo.accountFeatures?.[guardFeature] === null
                ? undefined
                : userInfo.accountFeatures?.[guardFeature].enabled
                  ? 'enabled'
                  : 'disabled';
        }
        return undefined;
    } catch (error) {
        return undefined;
    }
};

const TradingAnalysisGuard: React.FunctionComponent<TradingAnalysisGuardProps> = (props) => {
    const { component: Component, userInfo, guardFeature, t4pAnalysisGuard, ...restProps } = props;

    if (!tradingAnalysisIsAllowed(userInfo, guardFeature)) {
        return <Redirect to={`/${Pages.TRADING_ANALYSIS}`} />;
    }

    return (
        <Route
            {...restProps}
            render={(props) => {
                return <Component {...props} guardFeature={guardFeature} userInfo={userInfo} />;
            }}
        />
    );
};

export const mapStateToProps = (state) => ({
    userInfo: user_userInfo(state),
});

export const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(TradingAnalysisGuard));
