export const nFloor = (num: number, decimals: number): number => {
    const factor = Math.pow(10, decimals);
    return Math.floor(num * factor) / factor;
};

export const numberFormat = (num: number, decimals?: number): string => {
    if (decimals !== undefined) {
        const flooredNumber = nFloor(num, decimals);
        const formatedNumber = Intl.NumberFormat('en-US', {
            maximumFractionDigits: decimals,
            minimumFractionDigits: decimals,
            style: 'decimal',
        }).format(flooredNumber);

        return formatedNumber;
    } else {
        return Intl.NumberFormat('en-US', {
            maximumFractionDigits: 20,
            style: 'decimal',
        }).format(num);
    }
};
